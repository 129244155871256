$caret-width-base: 4px;
$caret-width-large: 5px;

/* =================================================
     Header
   ============================================== */
.header {
  padding: 10px 0;
  background-color: $color-accent;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 500;
  @include transition();
  //@include drop-shadow();
  @media handheld, only screen and (max-width: 1000px) {
    padding: 5px 0;
  }

  html.hero & {
    background-color: transparent;
  }
  html &.scrollnav {
    background-color: rgba(17, 86, 33, .95);
  }

  .sm-col {
    @include media-breakpoint-down(xs) {
      display: none;
      border-bottom: 1px solid $color-hr;
      padding: 8px 0;
      margin-bottom: 5px;
      text-align: center;
    }
  }
  a.logo {
    width: 200px;
    display: block;

    img {
      width: 100%;
      height: auto;
      display: block;
    }

    @media handheld, only screen and (max-width: 1000px) {}
  }
}

div.wrap {
  clear: both;
}
.wrap {
  clear: both;
  padding-top: 75px;

  /* Based on the height of the menu */
  @media handheld, only screen and (max-width: 1000px) {
    padding-top: 65px;
    /* Based on the height of the menu */
  }
}

html.hero {
  .wrap {
    padding-top: 0;
  }
}

.header.top {
  background-color: #fff;
}

.headroom {
  @include transition();
}

.headroom--pinned {
  transform: translateY(0%);
}

.headroom--unpinned {
  transform: translateY(-100%);
}
@media handheld, only screen and (max-width: 1000px) {
  .headroom--pinned {
    transform: none;
  }

  .headroom--unpinned {
    transform: none;
  }
}
// Main navigation.

.nav-primary {
  float: right;
  display: block;
  margin-right: -17px;
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    margin-left: 50px;
    text-align: right;

    li {
      float: left;
      margin: 0;
      z-index: 5001;
      padding: 7px 10px;
      @media handheld, only screen and (min-width: 1250px) {
        padding: 7px 17px;
      }

      a:active,
      a:hover,
      a:link,
      a:visited {
        display: block;
        padding: 7px 0 3px;
        margin: 0;
        -webkit-transition: color .2s ease-out;
        -moz-transition: color .2s ease-out;
        -o-transition: color .2s ease-out;
        transition: color .2s ease-out;
        font-family: $font-menu;

        /*
         * Menu font styles
         */
        font-size: 12px;
        letter-spacing: 0.02em;
        line-height: 200%;
        color: #fff;
        text-transform: uppercase;
        text-decoration: none;
        html.wf-active & {
          font-size: 22px;
          line-height: 100%;
          text-transform: uppercase;
          letter-spacing: 0.08em;
        }

      }
    }
    // Hover.
    li.active a:active,
    li.active a:link,
    li.active a:visited {
      //border-bottom: 1px solid rgba(255, 255, 255, .5);
    }
    // Active.
    li a:hover,
    li.active a:hover,
    li.hovered a {
      color: rgba(255, 255, 255, .9);
    }

    //
    // Caret
    //
    li.menu-item-has-children {
      a::after {
        content: "";
        display: inline-block;
        vertical-align: top;
        width: 0;
        height: 0;
        margin-left: 2px;
        margin-top: -3px;
        margin-right: 0;
        vertical-align: middle;
        border-top: 4px solid;
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
        @media handheld, only screen and (max-width: 1000px) {
          //display: inline-block;
        }
      }
    }

    li.menu-item-has-children {
      position: relative;
      @include transition();
      @media handheld, only screen and (max-width: 1000px) {
        //position: static;
      }

      ul.sub-menu::before {
        content: "";
        display: inline-block;
        vertical-align: top;
        width: 0;
        height: 0;
        margin-left: 2px;
        margin-top: -3px;
        margin-right: 0;
        vertical-align: middle;
        border-bottom: 8px solid lighten($color-base, 5%);
        border-right: 8px solid transparent;
        border-left: 8px solid transparent;
        position: absolute;
        z-index: 1001;
        left: 50%;
        margin-left: -10px;
        top: -4px;

        @media handheld, only screen and (max-width: 1000px) {
          //display: none;
          position: relative;
          top: -16px;
          margin: 0;
          left: 40px;
          border-bottom: 8px solid $color-accent;
        }
      }

      /* Sub menu */
      ul.sub-menu {
        display: none;
        position: absolute;
        margin: 0;
        z-index: 1001;
        background-color: lighten($color-base, 5%);
        @include rounded(3px);
        width: 300px;
        left: 50%;
        margin-left: -150px;
        top: 30px;
        padding: 5px 0;
        @include drop-shadow();
        @include opacity(0);
        @include transition(.1s);

        @media handheld, only screen and (max-width: 1000px) {
          position: relative;
          width: 100%;
          left: auto;
          margin: 0;
          padding: 0 0 20px 0;
          @include rounded(0);
          background-color: $color-accent;
        }



        &.hovered {
          display: block;
        }
        &.inplace {
          top: 45px;
          @include opacity(1);
        }

        li {
          display: block;
          float: none;
          text-align: left;
          margin: 0;
          padding: 0;

          a::after {

            display: none;
          }
        }

        a:active,
        a:hover,
        a:link,
        a:visited {
          color: #fff;
          display: block;
          padding: 9px 17px;
          //font-size: 19px;
          border: none !important;
          -webkit-transition: background-color .2s ease-out;
          -moz-transition: background-color .2s ease-out;
          -o-transition: background-color .2s ease-out;
          transition: background-color .2s ease-out;
        }

        a:hover {
          background-color: darken($color-accent, 6%);
          color: #fff;
        }

        @media handheld, only screen and (max-width: 1000px) {
          display: block;
          position: static;
          width: 100%;
          height: 100%;
          text-align: left;
          display: none;
          margin-top: -10px;

          a:active,
          a:hover,
          a:link,
          a:visited {
            background: transparent;
            //padding-left: 30px;
          }
        }
      }

      &.left {
        ul.sub-menu {
          left: auto;
          right: 0;
        }
      }
    }

  }
  // Breakpoint #3
  @media handheld, only screen and (max-width: 1000px) {
    padding: 36px 0 0;
    text-align: left;
    z-index: 2001;
    position: fixed;
    background-color: $color-accent;
    width: 250px;
    right: -250px;
    top: 0;
    height: 100%;
    margin: 0;
    z-index: 8000;
    @include transition(.3s);
    overflow: scroll;

    ul {
      margin-left: 0;
      text-align: left;

      li {
        display: block;
        float: none;
        margin: 0;
        padding: 0;
        border: 0;
        z-index: 5001;

        a:active,
        a:hover,
        a:link,
        a:visited {
          text-decoration: none;
          display: block;
          padding: 18px;
          @include transition();
          color: #fff;
          border: 0;
        }
        a:hover {
          background-color: transparent;
        }
      }


      li.active {
        background-image: none;
      }

      li.active a {
        background: transparent;
      }
      // Hover.
      li a:hover,
      li.active a:active,
      li.active a:hover,
      li.active a:link,
      li.active a:visited,
      li.hovered a {
        color: #fff;
      }
      li.contact.active {
        a:active,
        a:hover,
        a:link,
        a:visited {
          color: $color-base;
        }
      }
    }
  }
}

// Mobile only menu items.
.nav-primary ul li.mobile-only {
  display: none;
}
// Desktop only menu items.

.nav-primary ul li.desktop-only {
  display: inline-block;
}
@media handheld, only screen and (max-width: 1000px) {
  // Mobile only menu items.
  .nav-primary ul li.mobile-only {
    display: block;
  }
  // Desktop only menu items.
  .nav-primary ul li.desktop-only {
    display: none;
  }
}

#mobile-menu {
  cursor: pointer;
  display: none;
  position: fixed;
  z-index: 9999;
  top: 11px;
  right: 30px;
  @include media-breakpoint-down(xs) {
    right: 10px;
  }

  .tcon {
    background: transparent !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none !important;
    cursor: pointer;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    height: 50px;
    transition: 0.3s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 30px;
    background: transparent;
    outline: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-tap-highlight-color: transparent;
  }

  .tcon > * {
    display: block;
  }

  .tcon:focus,
  .tcon:hover {
    outline: none;
    background: transparent;
  }

  .tcon::-moz-focus-inner {
    border: 0;
  }

  .tcon-menu__lines {
    display: inline-block;
    height: 2px;
    width: 30px;
    //border-radius: 2.85714px;
    transition: 0.3s;
    background: $color-hamburger;
    position: relative;
  }

  .tcon-menu__lines::after,
  .tcon-menu__lines::before {
    display: inline-block;
    height: 2px;
    width: 30px;
    //border-radius: 2.85714px;
    transition: 0.3s;
    background: $color-hamburger;
    content: '';
    position: absolute;
    left: 0;
    -webkit-transform-origin: 2.85714px center;
    transform-origin: 2.85714px center;
    width: 100%;
  }

  .tcon-menu__lines::before {
    top: 10px;
  }

  .tcon-menu__lines::after {
    top: -10px;
  }

  .tcon-transform .tcon-menu__lines {
    -webkit-transform: scale3d(0.8, 0.8, 0.8);
    transform: scale3d(0.8, 0.8, 0.8);
  }

  .tcon-menu--xcross {
    width: auto;
  }

  .tcon-menu--xcross.tcon-transform .tcon-menu__lines {
    background: transparent;
  }

  .tcon-menu--xcross.tcon-transform .tcon-menu__lines::after,
  .tcon-menu--xcross.tcon-transform .tcon-menu__lines::before {
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    top: 0;
    width: 40px;
  }

  .tcon-menu--xcross.tcon-transform .tcon-menu__lines::before {
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    background: $color-hamburger-menu-open;
  }

  .tcon-menu--xcross.tcon-transform .tcon-menu__lines::after {
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    background: $color-hamburger-menu-open;
  }

  .tcon-visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .tcon-visuallyhidden:active,
  .tcon-visuallyhidden:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
  }
}
// Mobile menu changes.
@media handheld, only screen and (max-width: 1000px) {
  #mobile-menu {
    display: block;
    @include transition(.2s);
  }

  .wrapper {
    -webkit-transition: right 0.2s ease-in-out;
    -moz-transition: right 0.2s ease-in-out;
    -o-transition: right 0.2s ease-in-out;
    transition: right 0.2s ease-in-out;
    position: absolute;
    right: 0;
  }

  .wrapper.with-transitions {
    .footer,
    .h-bar,
    .logo,
    main {
      @include transition(.2s);
    }
  }

  .wrapper.menu-open .nav-primary {
    right: 0;
    -webkit-box-shadow: -25px 0 100px rgba(0, 0, 0, .4);
    -moz-box-shadow: -25px 0 100px rgba(0, 0, 0, .4);
    box-shadow: -25px 0 100px rgba(0, 0, 0, .4);
  }

}
