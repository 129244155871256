section.grid {
  img {
    display: block;
    margin: 0;
    padding: 0;
    max-width: 100%;
    height: auto;
  }

  .row.skinny-row {
    //background: black;
    padding: 0;
    margin: 0 -25px;
  }
}
