.footer.footer {
  margin: 0;

  .footer-inner {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  &, p, ul li {
    font-size: 14px;
  }
  &, p, h3, h4, h5, ul li {
    color: #fff;
  }

  p {
    margin: 0;
  }
  a.butt {
    padding: 12px;
  }


  .left {

    @media handheld, only screen and (max-width: 768px) {
      text-align: center;
    }
  }

  .right {
    text-align: right;


    .icon-pin {
      padding-top: 30px;
    }

    @media handheld, only screen and (max-width: 768px) {
      text-align: center;
    }

  }

  .sn {
    margin-bottom: 20px;
    @include flex-justify-end;
    @media handheld, only screen and (max-width: 768px) {
      @include flex-justify-center;
    }
  }

  .legal {
    font-size: 14px;
    text-align: center;
    &, a {
      color: rgba(0, 0, 0, .3);
    }
  }
}
