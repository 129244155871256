.sn {
  .svg & {
    @include flex-it;
    @include flex-align-item-center;
  }
  font-size: 0px;
  a {
    background: url("#{$images}/sn-sprites.png") center center repeat-y;
    background-size: 20px auto;
    margin: 0;
    display: inline-block;
    border: 0;
    @include transition(.2s);
    @include opacity(1);
    text-decoration: none;
    margin: 0 8px;
    @include opacity(.6);
    svg {
      display: none;
    }

    .svg & {
      @include opacity(1);
      background: transparent;

      svg {
        width: 100%;
        height: auto;
        max-height: 25px;
        max-width: 25px;
        display: block;

        .st0 {
          @include transition();
          fill: $color-accent;
        }
      }

      &.fb {
        svg {
          //width: 8px;
          //height: 13px;
        }
      }

      &:hover {
        svg {
          .st0 {
            fill: $color-base;
          }
        }
      }
    }
  }

  &.on-color {
    a {
      .svg & {
        svg {
          .st0 {
            fill: #fff;
          }
        }
      }
    }
  }
}

.sn a.twitter {
  background-position: 6px 10px;
}

.sn a.fb {
  background-position: 6px -83px;
}

.sn a.google {
  background-position: 6px -173px;
}

.sn a.instagram {
  background-position: 6px -263px;
}

.sn a:hover {
  @include opacity(1);
}
