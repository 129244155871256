/*!
 * Bootstrap v4.0.0-beta (https://getbootstrap.com)
 * Copyright 2011-2017 The Bootstrap Authors
 * Copyright 2011-2017 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a,
  a:visited {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ddd !important; } }

html {
  box-sizing: border-box;
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent; }

*,
*::before,
*::after {
  box-sizing: inherit; }

@-ms-viewport {
  width: device-width; }

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #212529;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: none !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: bold; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

a,
area,
button,
[role="button"],
input,
label,
select,
summary,
textarea {
  touch-action: manipulation; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #868e96;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: left; }

label {
  display: inline-block;
  margin-bottom: .5rem; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.1; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.1; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.1; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.1; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: normal; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 5px; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #868e96; }
  .blockquote-footer::before {
    content: "\2014 \00A0"; }

.img-fluid, .wp-caption > img {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  transition: all 0.2s ease-in-out;
  max-width: 100%;
  height: auto; }

.figure, .wp-caption {
  display: inline-block; }

.figure-img, .wp-caption > img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption, .wp-caption-text {
  font-size: 90%;
  color: #868e96; }

.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: 25px;
  padding-left: 25px;
  width: 100%; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 25px;
  padding-left: 25px;
  width: 100%; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -25px;
  margin-left: -25px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 25px;
  padding-left: 25px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%; }

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%; }

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%; }

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%; }

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; } }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #e9ecef; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #e9ecef; }
  .table tbody + tbody {
    border-top: 2px solid #e9ecef; }
  .table .table {
    background-color: #fff; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #e9ecef; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #e9ecef; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff; }

.table-hover .table-primary:hover {
  background-color: #9fcdff; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #9fcdff; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #dddfe2; }

.table-hover .table-secondary:hover {
  background-color: #cfd2d6; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #cfd2d6; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.thead-inverse th {
  color: #fff;
  background-color: #212529; }

.thead-default th {
  color: #495057;
  background-color: #e9ecef; }

.table-inverse {
  color: #fff;
  background-color: #212529; }
  .table-inverse th,
  .table-inverse td,
  .table-inverse thead th {
    border-color: #32383e; }
  .table-inverse.table-bordered {
    border: 0; }
  .table-inverse.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-inverse.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 991px) {
  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive.table-bordered {
      border: 0; } }

.btn {
  display: inline-block;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  line-height: 1.25;
  border-radius: 0.25rem;
  transition: all 0.15s ease-in-out; }
  .btn:focus, .btn:hover {
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: .65; }
  .btn:active, .btn.nice-select.open, .btn.active {
    background-image: none; }

a.btn.disabled,
fieldset[disabled] a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    background-color: #007bff;
    border-color: #007bff; }
  .btn-primary:active, .btn-primary.nice-select.open, .btn-primary.active,
  .show > .btn-primary.dropdown-toggle {
    background-color: #0069d9;
    background-image: none;
    border-color: #0062cc; }

.btn-secondary {
  color: #fff;
  background-color: #868e96;
  border-color: #868e96; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #727b84;
    border-color: #6c757d; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 3px rgba(134, 142, 150, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    background-color: #868e96;
    border-color: #868e96; }
  .btn-secondary:active, .btn-secondary.nice-select.open, .btn-secondary.active,
  .show > .btn-secondary.dropdown-toggle {
    background-color: #727b84;
    background-image: none;
    border-color: #6c757d; }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 3px rgba(40, 167, 69, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:active, .btn-success.nice-select.open, .btn-success.active,
  .show > .btn-success.dropdown-toggle {
    background-color: #218838;
    background-image: none;
    border-color: #1e7e34; }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 3px rgba(23, 162, 184, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:active, .btn-info.nice-select.open, .btn-info.active,
  .show > .btn-info.dropdown-toggle {
    background-color: #138496;
    background-image: none;
    border-color: #117a8b; }

.btn-warning {
  color: #111;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #111;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 3px rgba(255, 193, 7, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:active, .btn-warning.nice-select.open, .btn-warning.active,
  .show > .btn-warning.dropdown-toggle {
    background-color: #e0a800;
    background-image: none;
    border-color: #d39e00; }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 3px rgba(220, 53, 69, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:active, .btn-danger.nice-select.open, .btn-danger.active,
  .show > .btn-danger.dropdown-toggle {
    background-color: #c82333;
    background-image: none;
    border-color: #bd2130; }

.btn-light {
  color: #111;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #111;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 3px rgba(248, 249, 250, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:active, .btn-light.nice-select.open, .btn-light.active,
  .show > .btn-light.dropdown-toggle {
    background-color: #e2e6ea;
    background-image: none;
    border-color: #dae0e5; }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 3px rgba(52, 58, 64, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:active, .btn-dark.nice-select.open, .btn-dark.active,
  .show > .btn-dark.dropdown-toggle {
    background-color: #23272b;
    background-image: none;
    border-color: #1d2124; }

.btn-outline-primary {
  color: #007bff;
  background-color: transparent;
  background-image: none;
  border-color: #007bff; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent; }
  .btn-outline-primary:active, .btn-outline-primary.nice-select.open, .btn-outline-primary.active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }

.btn-outline-secondary {
  color: #868e96;
  background-color: transparent;
  background-image: none;
  border-color: #868e96; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #868e96;
    border-color: #868e96; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 3px rgba(134, 142, 150, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #868e96;
    background-color: transparent; }
  .btn-outline-secondary:active, .btn-outline-secondary.nice-select.open, .btn-outline-secondary.active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #868e96;
    border-color: #868e96; }

.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 3px rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:active, .btn-outline-success.nice-select.open, .btn-outline-success.active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 3px rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:active, .btn-outline-info.nice-select.open, .btn-outline-info.active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }

.btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #fff;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 3px rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:active, .btn-outline-warning.nice-select.open, .btn-outline-warning.active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #fff;
    background-color: #ffc107;
    border-color: #ffc107; }

.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 3px rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:active, .btn-outline-danger.nice-select.open, .btn-outline-danger.active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #fff;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 3px rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:active, .btn-outline-light.nice-select.open, .btn-outline-light.active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #fff;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 3px rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:active, .btn-outline-dark.nice-select.open, .btn-outline-dark.active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }

.btn-link {
  font-weight: normal;
  color: #007bff;
  border-radius: 0; }
  .btn-link, .btn-link:active, .btn-link.nice-select.open, .btn-link.active, .btn-link:disabled {
    background-color: transparent; }
  .btn-link, .btn-link:focus, .btn-link:active, .btn-link.nice-select.open {
    border-color: transparent;
    box-shadow: none; }
  .btn-link:hover {
    border-color: transparent; }
  .btn-link:focus, .btn-link:hover {
    color: #0056b3;
    text-decoration: underline;
    background-color: transparent; }
  .btn-link:disabled {
    color: #868e96; }
    .btn-link:disabled:focus, .btn-link:disabled:hover {
      text-decoration: none; }

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }

.btn-block + .btn-block {
  margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  opacity: 0;
  transition: opacity 0.15s linear; }
  .fade.show {
    opacity: 1; }

.collapse {
  display: none; }
  .collapse.show {
    display: block; }

tr.collapse.show {
  display: table-row; }

tbody.collapse.show {
  display: table-row-group; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:focus, .nav-link:hover {
    text-decoration: none; }
  .nav-link.disabled {
    color: #868e96; }

.nav-tabs {
  border-bottom: 1px solid #ddd; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
      border-color: #e9ecef #e9ecef #ddd; }
    .nav-tabs .nav-link.disabled {
      color: #868e96;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #ddd #ddd #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }
  .nav-pills .nav-link.active,
  .show > .nav-pills .nav-link {
    color: #fff;
    background-color: #007bff; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:focus, .close:hover {
    color: #000;
    text-decoration: none;
    opacity: .75; }

button.close {
  padding: 0;
  background: transparent;
  border: 0;
  -webkit-appearance: none; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:focus, a.bg-primary:hover {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #868e96 !important; }

a.bg-secondary:focus, a.bg-secondary:hover {
  background-color: #6c757d !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:focus, a.bg-success:hover {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:focus, a.bg-info:hover {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:focus, a.bg-warning:hover {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:focus, a.bg-danger:hover {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:focus, a.bg-light:hover {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:focus, a.bg-dark:hover {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #e9ecef !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #868e96 !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-circle {
  border-radius: 50%; }

.rounded-0 {
  border-radius: 0; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

.d-print-block {
  display: none !important; }
  @media print {
    .d-print-block {
      display: block !important; } }

.d-print-inline {
  display: none !important; }
  @media print {
    .d-print-inline {
      display: inline !important; } }

.d-print-inline-block {
  display: none !important; }
  @media print {
    .d-print-inline-block {
      display: inline-block !important; } }

@media print {
  .d-print-none {
    display: none !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only, .screen-reader-text {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.sr-only-focusable:active, .screen-reader-text:active, .screen-reader-text.nice-select.open, .sr-only-focusable.nice-select.open, .sr-only-focusable:focus, .screen-reader-text:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.m-0 {
  margin: 0 !important; }

.mt-0 {
  margin-top: 0 !important; }

.mr-0 {
  margin-right: 0 !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.ml-0 {
  margin-left: 0 !important; }

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important; }

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1 {
  margin-top: 0.25rem !important; }

.mr-1 {
  margin-right: 0.25rem !important; }

.mb-1 {
  margin-bottom: 0.25rem !important; }

.ml-1 {
  margin-left: 0.25rem !important; }

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important; }

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2 {
  margin-top: 0.5rem !important; }

.mr-2 {
  margin-right: 0.5rem !important; }

.mb-2 {
  margin-bottom: 0.5rem !important; }

.ml-2 {
  margin-left: 0.5rem !important; }

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important; }

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3 {
  margin-top: 1rem !important; }

.mr-3 {
  margin-right: 1rem !important; }

.mb-3 {
  margin-bottom: 1rem !important; }

.ml-3 {
  margin-left: 1rem !important; }

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important; }

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4 {
  margin-top: 1.5rem !important; }

.mr-4 {
  margin-right: 1.5rem !important; }

.mb-4 {
  margin-bottom: 1.5rem !important; }

.ml-4 {
  margin-left: 1.5rem !important; }

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important; }

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5 {
  margin-top: 3rem !important; }

.mr-5 {
  margin-right: 3rem !important; }

.mb-5 {
  margin-bottom: 3rem !important; }

.ml-5 {
  margin-left: 3rem !important; }

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important; }

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0 {
  padding-top: 0 !important; }

.pr-0 {
  padding-right: 0 !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.pl-0 {
  padding-left: 0 !important; }

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important; }

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1 {
  padding-top: 0.25rem !important; }

.pr-1 {
  padding-right: 0.25rem !important; }

.pb-1 {
  padding-bottom: 0.25rem !important; }

.pl-1 {
  padding-left: 0.25rem !important; }

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important; }

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2 {
  padding-top: 0.5rem !important; }

.pr-2 {
  padding-right: 0.5rem !important; }

.pb-2 {
  padding-bottom: 0.5rem !important; }

.pl-2 {
  padding-left: 0.5rem !important; }

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important; }

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3 {
  padding-top: 1rem !important; }

.pr-3 {
  padding-right: 1rem !important; }

.pb-3 {
  padding-bottom: 1rem !important; }

.pl-3 {
  padding-left: 1rem !important; }

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important; }

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4 {
  padding-top: 1.5rem !important; }

.pr-4 {
  padding-right: 1.5rem !important; }

.pb-4 {
  padding-bottom: 1.5rem !important; }

.pl-4 {
  padding-left: 1.5rem !important; }

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important; }

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5 {
  padding-top: 3rem !important; }

.pr-5 {
  padding-right: 3rem !important; }

.pb-5 {
  padding-bottom: 3rem !important; }

.pl-5 {
  padding-left: 3rem !important; }

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important; }

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto {
  margin-top: auto !important; }

.mr-auto {
  margin-right: auto !important; }

.mb-auto {
  margin-bottom: auto !important; }

.ml-auto {
  margin-left: auto !important; }

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important; }

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0 {
    margin-left: 0 !important; }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1 {
    margin-left: 0.25rem !important; }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2 {
    margin-left: 0.5rem !important; }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3 {
    margin-left: 1rem !important; }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4 {
    margin-left: 1.5rem !important; }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5 {
    margin-left: 3rem !important; }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0 {
    padding-left: 0 !important; }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1 {
    padding-left: 0.25rem !important; }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2 {
    padding-left: 0.5rem !important; }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3 {
    padding-left: 1rem !important; }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4 {
    padding-left: 1.5rem !important; }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5 {
    padding-left: 3rem !important; }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto {
    margin-left: auto !important; }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0 {
    margin-top: 0 !important; }
  .mr-md-0 {
    margin-right: 0 !important; }
  .mb-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0 {
    margin-left: 0 !important; }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1 {
    margin-left: 0.25rem !important; }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2 {
    margin-left: 0.5rem !important; }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3 {
    margin-left: 1rem !important; }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4 {
    margin-left: 1.5rem !important; }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5 {
    margin-left: 3rem !important; }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0 {
    padding-top: 0 !important; }
  .pr-md-0 {
    padding-right: 0 !important; }
  .pb-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0 {
    padding-left: 0 !important; }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1 {
    padding-left: 0.25rem !important; }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2 {
    padding-left: 0.5rem !important; }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3 {
    padding-left: 1rem !important; }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4 {
    padding-left: 1.5rem !important; }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5 {
    padding-left: 3rem !important; }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto {
    margin-top: auto !important; }
  .mr-md-auto {
    margin-right: auto !important; }
  .mb-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto {
    margin-left: auto !important; }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0 {
    margin-left: 0 !important; }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1 {
    margin-left: 0.25rem !important; }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2 {
    margin-left: 0.5rem !important; }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3 {
    margin-left: 1rem !important; }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4 {
    margin-left: 1.5rem !important; }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5 {
    margin-left: 3rem !important; }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0 {
    padding-left: 0 !important; }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1 {
    padding-left: 0.25rem !important; }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2 {
    padding-left: 0.5rem !important; }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3 {
    padding-left: 1rem !important; }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4 {
    padding-left: 1.5rem !important; }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5 {
    padding-left: 3rem !important; }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto {
    margin-left: auto !important; }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0 {
    margin-left: 0 !important; }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1 {
    margin-left: 0.25rem !important; }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2 {
    margin-left: 0.5rem !important; }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3 {
    margin-left: 1rem !important; }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4 {
    margin-left: 1.5rem !important; }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5 {
    margin-left: 3rem !important; }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0 {
    padding-left: 0 !important; }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1 {
    padding-left: 0.25rem !important; }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2 {
    padding-left: 0.5rem !important; }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3 {
    padding-left: 1rem !important; }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4 {
    padding-left: 1.5rem !important; }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5 {
    padding-left: 3rem !important; }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto {
    margin-left: auto !important; }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; } }

.text-justify {
  text-align: justify !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-normal {
  font-weight: normal; }

.font-weight-bold {
  font-weight: bold; }

.font-italic {
  font-style: italic; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:focus, a.text-primary:hover {
  color: #0062cc !important; }

.text-secondary {
  color: #868e96 !important; }

a.text-secondary:focus, a.text-secondary:hover {
  color: #6c757d !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:focus, a.text-success:hover {
  color: #1e7e34 !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:focus, a.text-info:hover {
  color: #117a8b !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:focus, a.text-warning:hover {
  color: #d39e00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:focus, a.text-danger:hover {
  color: #bd2130 !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:focus, a.text-light:hover {
  color: #dae0e5 !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:focus, a.text-dark:hover {
  color: #1d2124 !important; }

.text-muted {
  color: #868e96 !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

/*---------------------------------------------------
    LESS Elements 0.9
  ---------------------------------------------------
    A set of useful LESS mixins
    More info at: http://lesselements.com
  ---------------------------------------------------*/
/* =================================================
  Mixins
============================================== */
html body main.main {
  padding: 0; }

.container-fluid.mgtop {
  margin-top: 40px; }

/* =================================================
     Regular tag styling
   ============================================== */
body {
  background: #fff; }

body,
html {
  height: 100%;
  padding: 0;
  margin: 0; }

body,
input,
li,
td,
textarea {
  font-family: "proxima-nova", sans-serif;
  font-size: 19px;
  color: #262626;
  font-weight: 400; }

@media handheld, only screen and (max-width: 767px) {
  body,
  input,
  li,
  td,
  textarea {
    font-size: 16px; } }

.wrapper {
  width: 100%; }

p {
  margin: 0 0 1.3em;
  line-height: 180%; }

b {
  font-weight: normal; }

::selection {
  background: #115626;
  /* Safari */
  color: #fff; }

::-moz-selection {
  background: #115626;
  /* Firefox */
  color: #fff; }

ol li,
ul li {
  margin-bottom: 8px; }

ul li h4 {
  display: inline-block; }

th {
  font-family: "proxima-nova", sans-serif, sans-serif; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 20px;
  line-height: 130%;
  color: #1a1a1a;
  font-family: "franklin-gothic-ext-comp-urw", sans-serif;
  font-weight: 300; }

h1 {
  font-size: 32px;
  margin: 0 0 25px;
  line-height: 190%;
  font-style: normal; }
  html.wf-active h1 {
    font-size: 60px;
    line-height: 100%; }
  @media handheld, only screen and (max-width: 768px) {
    h1 {
      font-size: 45px; } }
  h1 span {
    display: block;
    color: #1a1a1a; }

h2 {
  font-size: 23px;
  line-height: 200%; }
  html.wf-active h2 {
    font-size: 43px;
    line-height: 110%; }
  @media handheld, only screen and (max-width: 768px) {
    h2 {
      font-size: 31px; } }

h3 {
  margin-bottom: 29px;
  line-height: 200%;
  font-size: 19px; }
  html.wf-active h3 {
    font-size: 35px;
    line-height: 110%; }

h4 {
  font-size: 14px;
  line-height: 200%;
  margin: 0 0 14px; }
  html.wf-active h4 {
    font-size: 26px;
    line-height: 110%; }

h5 {
  font-size: 15px;
  font-family: "proxima-nova", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: .08em;
  margin: 0 0 14px; }

h6 {
  font-size: 15px;
  font-family: "proxima-nova", sans-serif;
  text-transform: uppercase;
  letter-spacing: .08em;
  margin: 0 0 14px; }

blockquote {
  border-left: 6px solid #115626;
  padding-left: 30px; }

.hl {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.45); }

.hl-alt {
  font-size: 18px; }

@media handheld, only screen and (max-width: 768px) {
  .hl {
    font-size: 18px; } }

hr {
  height: 1px;
  border: none;
  border-bottom: 1px solid #D7DDD9;
  background: transparent;
  margin: 40px 0;
  clear: both; }

hr.sm-pd {
  margin: 20px 0; }

hr.mini {
  width: 30px;
  margin: 20px 0;
  display: block;
  border-bottom: 1px solid #115626;
  text-align: left; }
  hr.mini.c2 {
    border-color: #7D8491; }
  hr.mini.mgtop {
    margin-top: 50px; }
  hr.mini.mgbot {
    margin-bottom: 50px; }

hr.ctr {
  margin-left: auto;
  margin-right: auto;
  text-align: center; }

b,
strong {
  font-weight: 600; }

.sub {
  font-size: 13px;
  letter-spacing: 0.1em;
  line-height: 100%;
  margin: 0 0 30px;
  display: block;
  color: #7D8491; }

@media handheld, only screen and (max-width: 767px) {
  .sub {
    font-size: 12px;
    line-height: 100%;
    margin: 0 0 8px; } }

i {
  font-style: italic; }

a:active, a.nice-select.open,
a:hover,
a:link,
a:visited {
  color: #115626;
  text-decoration: none;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  padding-bottom: 0;
  border-bottom: none; }

a:hover {
  color: #000; }

fieldset {
  border: 1px solid #D7DDD9;
  padding: 15px 0 0;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  margin: 0; }
  fieldset .submit-container {
    background: #E5E5E5;
    padding: 15px;
    text-align: center; }

fieldset legend {
  padding: 10px;
  font-size: 18px;
  color: #115626;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  margin: 0 auto; }

picture {
  display: block;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto; }
  picture img {
    width: 100%;
    display: block; }

.ctr {
  text-align: center; }

/* =================================================
  Standard mobile styles
============================================== */
/* =================================================
     Page layout - widths, etc.
   ============================================== */
.container-fluid {
  max-width: 1600px;
  padding: 0; }

.row {
  margin-left: auto;
  margin-right: auto;
  padding: 0 50px; }
  @media handheld, only screen and (max-width: 1000px) {
    .row {
      padding: 0 20px; } }
  @media handheld, only screen and (max-width: 768px) {
    .row {
      padding: 0; } }

/*

This generates classes like this:
mt-3-md-up (Adds margin top to md cols of size 3)

The number is the size of the padding or margin 1 through 5.
*/
.mt-1-xs-up {
  margin-top: 0.25rem !important; }

.mt-2-xs-up {
  margin-top: 0.5rem !important; }

.mt-3-xs-up {
  margin-top: 0.75rem !important; }

.mt-4-xs-up {
  margin-top: 1rem !important; }

.mt-5-xs-up {
  margin-top: 1.25rem !important; }

.mt-6-xs-up {
  margin-top: 1.5rem !important; }

.mb-1-xs-up {
  margin-bottom: 0.25rem !important; }

.mb-2-xs-up {
  margin-bottom: 0.5rem !important; }

.mb-3-xs-up {
  margin-bottom: 0.75rem !important; }

.mb-4-xs-up {
  margin-bottom: 1rem !important; }

.mb-5-xs-up {
  margin-bottom: 1.25rem !important; }

.mb-6-xs-up {
  margin-bottom: 1.5rem !important; }

@media (max-width: 575px) {
  .mt-1-xs-down {
    margin-top: 0.25rem !important; } }

@media (max-width: 575px) {
  .mt-2-xs-down {
    margin-top: 0.5rem !important; } }

@media (max-width: 575px) {
  .mt-3-xs-down {
    margin-top: 0.75rem !important; } }

@media (max-width: 575px) {
  .mt-4-xs-down {
    margin-top: 1rem !important; } }

@media (max-width: 575px) {
  .mt-5-xs-down {
    margin-top: 1.25rem !important; } }

@media (max-width: 575px) {
  .mt-6-xs-down {
    margin-top: 1.5rem !important; } }

@media (max-width: 575px) {
  .mb-1-xs-down {
    margin-bottom: 0.25rem !important; } }

@media (max-width: 575px) {
  .mb-2-xs-down {
    margin-bottom: 0.5rem !important; } }

@media (max-width: 575px) {
  .mb-3-xs-down {
    margin-bottom: 0.75rem !important; } }

@media (max-width: 575px) {
  .mb-4-xs-down {
    margin-bottom: 1rem !important; } }

@media (max-width: 575px) {
  .mb-5-xs-down {
    margin-bottom: 1.25rem !important; } }

@media (max-width: 575px) {
  .mb-6-xs-down {
    margin-bottom: 1.5rem !important; } }

@media (min-width: 576px) {
  .mt-1-sm-up {
    margin-top: 0.25rem !important; } }

@media (min-width: 576px) {
  .mt-2-sm-up {
    margin-top: 0.5rem !important; } }

@media (min-width: 576px) {
  .mt-3-sm-up {
    margin-top: 0.75rem !important; } }

@media (min-width: 576px) {
  .mt-4-sm-up {
    margin-top: 1rem !important; } }

@media (min-width: 576px) {
  .mt-5-sm-up {
    margin-top: 1.25rem !important; } }

@media (min-width: 576px) {
  .mt-6-sm-up {
    margin-top: 1.5rem !important; } }

@media (min-width: 576px) {
  .mb-1-sm-up {
    margin-bottom: 0.25rem !important; } }

@media (min-width: 576px) {
  .mb-2-sm-up {
    margin-bottom: 0.5rem !important; } }

@media (min-width: 576px) {
  .mb-3-sm-up {
    margin-bottom: 0.75rem !important; } }

@media (min-width: 576px) {
  .mb-4-sm-up {
    margin-bottom: 1rem !important; } }

@media (min-width: 576px) {
  .mb-5-sm-up {
    margin-bottom: 1.25rem !important; } }

@media (min-width: 576px) {
  .mb-6-sm-up {
    margin-bottom: 1.5rem !important; } }

@media (max-width: 767px) {
  .mt-1-sm-down {
    margin-top: 0.25rem !important; } }

@media (max-width: 767px) {
  .mt-2-sm-down {
    margin-top: 0.5rem !important; } }

@media (max-width: 767px) {
  .mt-3-sm-down {
    margin-top: 0.75rem !important; } }

@media (max-width: 767px) {
  .mt-4-sm-down {
    margin-top: 1rem !important; } }

@media (max-width: 767px) {
  .mt-5-sm-down {
    margin-top: 1.25rem !important; } }

@media (max-width: 767px) {
  .mt-6-sm-down {
    margin-top: 1.5rem !important; } }

@media (max-width: 767px) {
  .mb-1-sm-down {
    margin-bottom: 0.25rem !important; } }

@media (max-width: 767px) {
  .mb-2-sm-down {
    margin-bottom: 0.5rem !important; } }

@media (max-width: 767px) {
  .mb-3-sm-down {
    margin-bottom: 0.75rem !important; } }

@media (max-width: 767px) {
  .mb-4-sm-down {
    margin-bottom: 1rem !important; } }

@media (max-width: 767px) {
  .mb-5-sm-down {
    margin-bottom: 1.25rem !important; } }

@media (max-width: 767px) {
  .mb-6-sm-down {
    margin-bottom: 1.5rem !important; } }

@media (min-width: 768px) {
  .mt-1-md-up {
    margin-top: 0.25rem !important; } }

@media (min-width: 768px) {
  .mt-2-md-up {
    margin-top: 0.5rem !important; } }

@media (min-width: 768px) {
  .mt-3-md-up {
    margin-top: 0.75rem !important; } }

@media (min-width: 768px) {
  .mt-4-md-up {
    margin-top: 1rem !important; } }

@media (min-width: 768px) {
  .mt-5-md-up {
    margin-top: 1.25rem !important; } }

@media (min-width: 768px) {
  .mt-6-md-up {
    margin-top: 1.5rem !important; } }

@media (min-width: 768px) {
  .mb-1-md-up {
    margin-bottom: 0.25rem !important; } }

@media (min-width: 768px) {
  .mb-2-md-up {
    margin-bottom: 0.5rem !important; } }

@media (min-width: 768px) {
  .mb-3-md-up {
    margin-bottom: 0.75rem !important; } }

@media (min-width: 768px) {
  .mb-4-md-up {
    margin-bottom: 1rem !important; } }

@media (min-width: 768px) {
  .mb-5-md-up {
    margin-bottom: 1.25rem !important; } }

@media (min-width: 768px) {
  .mb-6-md-up {
    margin-bottom: 1.5rem !important; } }

@media (max-width: 991px) {
  .mt-1-md-down {
    margin-top: 0.25rem !important; } }

@media (max-width: 991px) {
  .mt-2-md-down {
    margin-top: 0.5rem !important; } }

@media (max-width: 991px) {
  .mt-3-md-down {
    margin-top: 0.75rem !important; } }

@media (max-width: 991px) {
  .mt-4-md-down {
    margin-top: 1rem !important; } }

@media (max-width: 991px) {
  .mt-5-md-down {
    margin-top: 1.25rem !important; } }

@media (max-width: 991px) {
  .mt-6-md-down {
    margin-top: 1.5rem !important; } }

@media (max-width: 991px) {
  .mb-1-md-down {
    margin-bottom: 0.25rem !important; } }

@media (max-width: 991px) {
  .mb-2-md-down {
    margin-bottom: 0.5rem !important; } }

@media (max-width: 991px) {
  .mb-3-md-down {
    margin-bottom: 0.75rem !important; } }

@media (max-width: 991px) {
  .mb-4-md-down {
    margin-bottom: 1rem !important; } }

@media (max-width: 991px) {
  .mb-5-md-down {
    margin-bottom: 1.25rem !important; } }

@media (max-width: 991px) {
  .mb-6-md-down {
    margin-bottom: 1.5rem !important; } }

@media (min-width: 992px) {
  .mt-1-lg-up {
    margin-top: 0.25rem !important; } }

@media (min-width: 992px) {
  .mt-2-lg-up {
    margin-top: 0.5rem !important; } }

@media (min-width: 992px) {
  .mt-3-lg-up {
    margin-top: 0.75rem !important; } }

@media (min-width: 992px) {
  .mt-4-lg-up {
    margin-top: 1rem !important; } }

@media (min-width: 992px) {
  .mt-5-lg-up {
    margin-top: 1.25rem !important; } }

@media (min-width: 992px) {
  .mt-6-lg-up {
    margin-top: 1.5rem !important; } }

@media (min-width: 992px) {
  .mb-1-lg-up {
    margin-bottom: 0.25rem !important; } }

@media (min-width: 992px) {
  .mb-2-lg-up {
    margin-bottom: 0.5rem !important; } }

@media (min-width: 992px) {
  .mb-3-lg-up {
    margin-bottom: 0.75rem !important; } }

@media (min-width: 992px) {
  .mb-4-lg-up {
    margin-bottom: 1rem !important; } }

@media (min-width: 992px) {
  .mb-5-lg-up {
    margin-bottom: 1.25rem !important; } }

@media (min-width: 992px) {
  .mb-6-lg-up {
    margin-bottom: 1.5rem !important; } }

@media (max-width: 1199px) {
  .mt-1-lg-down {
    margin-top: 0.25rem !important; } }

@media (max-width: 1199px) {
  .mt-2-lg-down {
    margin-top: 0.5rem !important; } }

@media (max-width: 1199px) {
  .mt-3-lg-down {
    margin-top: 0.75rem !important; } }

@media (max-width: 1199px) {
  .mt-4-lg-down {
    margin-top: 1rem !important; } }

@media (max-width: 1199px) {
  .mt-5-lg-down {
    margin-top: 1.25rem !important; } }

@media (max-width: 1199px) {
  .mt-6-lg-down {
    margin-top: 1.5rem !important; } }

@media (max-width: 1199px) {
  .mb-1-lg-down {
    margin-bottom: 0.25rem !important; } }

@media (max-width: 1199px) {
  .mb-2-lg-down {
    margin-bottom: 0.5rem !important; } }

@media (max-width: 1199px) {
  .mb-3-lg-down {
    margin-bottom: 0.75rem !important; } }

@media (max-width: 1199px) {
  .mb-4-lg-down {
    margin-bottom: 1rem !important; } }

@media (max-width: 1199px) {
  .mb-5-lg-down {
    margin-bottom: 1.25rem !important; } }

@media (max-width: 1199px) {
  .mb-6-lg-down {
    margin-bottom: 1.5rem !important; } }

.butt,
a.butt:active,
a.butt.nice-select.open,
a.butt:hover,
a.butt:link,
a.butt:visited,
button,
input.button {
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  border: none;
  padding: 14px 15px;
  -webkit-transition: all 0.2s ease-in-out ease-out;
  -moz-transition: all 0.2s ease-in-out ease-out;
  -o-transition: all 0.2s ease-in-out ease-out;
  transition: all 0.2s ease-in-out ease-out;
  font-weight: 400;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0.2em;
  background-color: #115626 !important;
  color: #fff !important;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

a:hover.butt,
button:hover,
input.button:hover {
  background-color: #7D8491 !important;
  color: #fff; }

.search-form label {
  font-weight: normal; }

.budget #budget-value-display {
  font-family: "franklin-gothic-ext-comp-urw", sans-serif;
  font-weight: 400;
  margin-bottom: 10px;
  font-size: 28px; }

.gform_wrapper .gform_body .gform_fields ul.gfield_checkbox li {
  display: block;
  vertical-align: middle;
  font-size: 0;
  margin: 10px 0;
  padding: 3px 0 !important;
  width: auto;
  height: auto;
  position: static; }
  .gform_wrapper .gform_body .gform_fields ul.gfield_checkbox li input {
    margin: 0;
    padding: 0; }
  .gform_wrapper .gform_body .gform_fields ul.gfield_checkbox li input:checked + label {
    font-weight: normal; }
  .gform_wrapper .gform_body .gform_fields ul.gfield_checkbox li label {
    line-height: 100%;
    margin: 0;
    padding: 0 5px; }

/* =================================================
     Form styles
   ============================================== */
.gform_wrapper .checkboxes label {
  float: left;
  margin: 5px 15px 5px 0;
  line-height: 120%; }

::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.15);
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out; }

:-moz-placeholder {
  /* Firefox 18- */
  color: rgba(0, 0, 0, 0.15);
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out; }

::-moz-placeholder {
  /* Firefox 19+ */
  color: rgba(0, 0, 0, 0.15);
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out; }

:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.15);
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out; }

.gform_wrapper .gform_body label.gfield_label {
  font-size: 17px;
  margin: 0 0 5px;
  font-weight: 400;
  display: block;
  line-height: 130%; }

html.js .gform_wrapper .gform_body label.gfield_label {
  margin-bottom: 4px;
  font-size: 19px; }

html .gform_wrapper .gform_body .gfield .ginput_complex label {
  color: rgba(0, 0, 0, 0.45);
  font-size: 15px; }

.gform_wrapper h3 {
  margin-bottom: 0.5em;
  font-size: 19px; }

html body .gform_wrapper .gform_body .ginput_container input,
html body .gform_wrapper .gform_body .ginput_container textarea,
html body input,
html body textarea {
  margin: 0;
  box-sizing: border-box;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  font-size: 17px;
  color: #1a1a1a;
  line-height: 170%;
  -webkit-box-shadow: inset none 1px 2px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: inset none 1px 2px rgba(0, 0, 0, 0.4);
  box-shadow: inset none 1px 2px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border: 0;
  background-color: #fff;
  border: 1px solid #D7DDD9;
  font-weight: 400;
  padding: 6px 9px; }

.gform_wrapper .gform_body input,
.gform_wrapper .gform_body textarea {
  width: 100%; }

.gform_wrapper {
  padding-bottom: 30px; }

.gform_wrapper .gform_body input:focus:-moz-placeholder {
  /* Firefox 18- */ }

.gform_wrapper .gform_body input:focus::-moz-placeholder {
  /* Firefox 19+ */ }

.gform_wrapper .gform_body input:focus,
.gform_wrapper .gform_body textarea:focus:-moz-placeholder {
  /* Firefox 18- */ }

.gform_wrapper .gform_body input:focus,
.gform_wrapper .gform_body textarea:focus::-moz-placeholder {
  /* Firefox 19+ */ }

.gform_wrapper .gform_body ul li {
  margin-bottom: 50px; }

.gform_wrapper .gform_body label.gfield_label .gfield_required {
  color: #115626; }

.gform_wrapper .gform_body .gfield_description {
  font-family: "proxima-nova", sans-serif;
  font-style: italic;
  margin-top: 3px;
  padding: 0;
  color: #B5B5B5; }

.gform_wrapper ul.gfield_checkbox li {
  display: inline-block;
  margin: 5px 30px 0 0; }

.gform_wrapper ul.gfield_checkbox li label {
  font-size: 15px; }

.gform_wrapper ul.gfield_checkbox li input[type=checkbox]:checked + label {
  font-weight: 100; }

.gform_wrapper .gform_body .gform_drop_area {
  padding: 40px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  background-color: #1a1a1a;
  border: none; }

.gform_wrapper .gform_body .ginput_container input.gform_button_select_files {
  width: auto;
  display: block;
  margin: 30px auto 0;
  padding: 15px 30px;
  font-size: 13px;
  border: 0;
  color: #fff;
  background-color: #115626;
  border: none;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out; }

.gform_confirmation_wrapper {
  text-align: center; }

body .gform_wrapper li.gfield.gfield_error,
body .gform_wrapper li.gfield.gfield_error.gfield_contains_required.gfield_creditcard_warning {
  background-color: transparent;
  margin-bottom: 0;
  padding: 0;
  border: 0; }

body .gform_wrapper li.gfield.gfield_error input,
body .gform_wrapper li.gfield.gfield_error textarea {
  border: 2px solid #CA0500; }

body .gform_wrapper .gform_body .gform_fields .gfield_error .validation_message {
  color: #CA0500;
  font-style: normal;
  padding: 0;
  margin: 0; }

body .gform_wrapper .validation_error {
  color: #CA0500;
  border: 0; }

.gform_wrapper .gform_body li.fl-left,
.gform_wrapper .gform_body li.fl-right {
  display: inline-block;
  width: 48%;
  padding: 0;
  float: left;
  clear: none; }

.gform_wrapper .gform_body li.fl-left {
  margin-right: 20px; }

.gform_wrapper .gform_body li.fl-right {
  clear: none; }

@media handheld, only screen and (max-width: 767px) {
  .gform_wrapper .gform_body li.fl-left,
  .gform_wrapper .gform_body li.fl-right {
    width: 100%;
    padding: 0;
    float: none;
    clear: both; } }

/* 3 column floater */
.gform_wrapper .gform_body li.fl3 {
  display: inline-block;
  width: 31.2%;
  margin-right: 2%;
  padding: 0;
  float: left;
  clear: none; }
  @media handheld, only screen and (max-width: 767px) {
    .gform_wrapper .gform_body li.fl3 {
      float: none;
      width: 100%;
      margin-right: 0; } }

.gform_wrapper .gform_body li.fl3.last {
  margin-right: 0; }

html body .gform_wrapper .chosen-container-multi .chosen-choices {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  border: 0; }

html body footer .gform_wrapper .chosen-container-multi .chosen-choices li.search-field input[type=text] {
  height: auto;
  padding: 9px; }

html body .gform_wrapper .chosen-container .chosen-results li.highlighted {
  background-color: #115626;
  background-image: none;
  color: #fff; }

html body .gform_wrapper .chosen-container-multi .chosen-choices li.search-field .default {
  color: #B5B5B5; }

html body .gform_wrapper .chosen-container-active .chosen-choices,
html body .gform_wrapper .chosen-container-active.chosen-with-drop .chosen-single,
html body .gform_wrapper .chosen-container-multi .chosen-choices,
html body .gform_wrapper .chosen-container-multi .chosen-choices li.search-choice,
html body .gform_wrapper .chosen-container-single .chosen-single {
  background-image: none;
  color: #1a1a1a;
  background-color: #fff;
  border: 1px solid #D7DDD9;
  -webkit-box-shadow: inset none 1px 2px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: inset none 1px 2px rgba(0, 0, 0, 0.4);
  box-shadow: inset none 1px 2px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  padding: 12px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  font-weight: 300;
  font-size: 18px;
  height: auto; }

html body .gform_wrapper .chosen-container-multi .chosen-choices .search-choice-focus {
  background-color: #fff;
  background-image: none; }

html body .gform_wrapper .chosen-container .chosen-drop {
  position: absolute;
  top: 100%;
  left: -9999px;
  z-index: 1010;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  border: 0;
  border-top: 0;
  background: #fff;
  box-shadow: none;
  -webkit-border-radius: 0 0 2px 2px;
  -moz-border-radius: 0 0 2px 2px;
  border-radius: 0 0 2px 2px;
  -webkit-box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2); }

html body .gform_wrapper .chosen-container .chosen-results-scroll-down span,
html body .gform_wrapper .chosen-container .chosen-results-scroll-up span,
html body .gform_wrapper .chosen-container-multi .chosen-choices .search-choice .search-choice-close,
html body .gform_wrapper .chosen-container-single .chosen-single abbr,
html body .gform_wrapper .chosen-container-single .chosen-single div b,
html body .gform_wrapper .chosen-rtl .chosen-search input[type="text"] {
  background-image: none !important; }

html body .form .gform_footer .button.gform_button {
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  border: none;
  padding: 14px 15px;
  -webkit-transition: all 0.2s ease-in-out ease-out;
  -moz-transition: all 0.2s ease-in-out ease-out;
  -o-transition: all 0.2s ease-in-out ease-out;
  transition: all 0.2s ease-in-out ease-out;
  font-weight: 400;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0.2em;
  background-color: #115626 !important;
  color: #fff !important;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

html body .form .gform_footer .button.gform_button:hover {
  background-color: #7D8491 !important;
  color: #fff; }

html body .gform_wrapper .gform_body li.gsection {
  clear: both;
  padding-top: 30px; }
  html body .gform_wrapper .gform_body li.gsection h2.gsection_title {
    color: #115626;
    display: block;
    margin-top: 0; }

html body .gform_wrapper .gform_body li.gsection:first-child {
  padding-top: 0; }

.main {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 25px;
  padding-left: 25px; }
  @media (min-width: 576px) {
    .main {
      flex: 0 0 100%;
      max-width: 100%; }
      .sidebar-primary .main {
        flex: 0 0 66.6666666667%;
        max-width: 66.6666666667%; } }

.sidebar {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 25px;
  padding-left: 25px; }
  @media (min-width: 576px) {
    .sidebar {
      flex: 0 0 33.3333333333%;
      max-width: 33.3333333333%; } }

/*
 * jQuery FlexSlider v2.6.0
 * http://www.woothemes.com/flexslider/
 *
 * Copyright 2012 WooThemes
 * Free to use under the GPLv2 and later license.
 * http://www.gnu.org/licenses/gpl-2.0.html
 *
 * Contributing author: Tyler Smith ($mbmufffin)
 *
 */
/* ====================================================================================================================
 * FONT-FACE
 * ====================================================================================================================*/
/*
$font-face {
  font-family: 'flexslider-icon';
  src: url('fonts/flexslider-icon.eot');
  src: url('fonts/flexslider-icon.eot?#iefix') format('embedded-opentype'), url('fonts/flexslider-icon.woff') format('woff'), url('fonts/flexslider-icon.ttf') format('truetype'), url('fonts/flexslider-icon.svg#flexslider-icon') format('svg');
  font-weight: normal;
  font-style: normal;
}
*/
/* ====================================================================================================================
 * RESETS
 * ====================================================================================================================*/
.flex-container a:hover,
.flex-slider a:hover {
  outline: none; }

.flex-control-nav,
.flex-direction-nav,
.slides,
.slides > li {
  margin: 0;
  padding: 0;
  list-style: none; }

.flex-pauseplay span {
  text-transform: capitalize; }

/* ====================================================================================================================
 * BASE STYLES
 * ====================================================================================================================*/
.flexslider {
  margin: 0;
  padding: 0; }

.flexslider .slides > li {
  display: none;
  -webkit-backface-visibility: hidden; }

.flexslider .slides img {
  width: 100%;
  display: block; }

.flexslider .slides:after {
  content: "\0020";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0; }

html[xmlns] .flexslider .slides {
  display: block; }

* html .flexslider .slides {
  height: 1%; }

.no-js .flexslider .slides > li:first-child {
  display: block; }

/* ====================================================================================================================
 * DEFAULT THEME
 * ====================================================================================================================*/
.flexslider {
  margin: 0;
  position: relative;
  zoom: 1; }

.flexslider .slides {
  zoom: 1; }

.flexslider .slides img {
  height: auto;
  -moz-user-select: none; }

.flex-viewport {
  max-height: 2000px;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease; }

.loading .flex-viewport {
  max-height: 300px; }

.carousel li {
  margin-right: 5px; }

.flex-direction-nav {
  height: 0; }

.flex-direction-nav a {
  text-decoration: none;
  display: block;
  width: 40px;
  height: 68px;
  margin: -34px 0 0;
  position: absolute;
  top: 50%;
  z-index: 10;
  overflow: hidden;
  cursor: pointer; }
  @media handheld, only screen and (max-width: 768px) {
    .flex-direction-nav a {
      display: none; } }

.flex-direction-nav a:before {
  font-family: "flexslider-icon";
  font-size: 40px;
  display: inline-block;
  content: '';
  color: rgba(0, 0, 0, 0.8);
  background: url("../../assets/images/arrow-left.png") top center no-repeat;
  width: 35px;
  height: 68px;
  background-size: 35px 68px; }
  @media handheld, only screen and (max-width: 768px) {
    .flex-direction-nav a:before {
      display: none; } }

.flex-direction-nav a.flex-next:before {
  content: '';
  background: url("../../assets/images/arrow-left.png") top center no-repeat;
  width: 35px;
  height: 68px;
  background-size: 35px 68px;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg); }

.flex-direction-nav .flex-prev {
  left: -50px; }

.flex-direction-nav .flex-next {
  right: -50px;
  text-align: right; }

.flexslider .flex-direction-nav .flex-prev {
  left: 10px; }

.flexslider:hover .flex-direction-nav .flex-prev:hover {
  opacity: 1; }

.flexslider .flex-direction-nav .flex-next {
  right: 10px; }

.flexslider:hover .flex-direction-nav .flex-next:hover {
  opacity: 1; }

.flex-direction-nav .flex-disabled {
  opacity: 0 !important;
  filter: alpha(opacity=0);
  cursor: default;
  z-index: -1; }

.flex-pauseplay a {
  display: block;
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 5px;
  left: 10px;
  opacity: 0.8;
  z-index: 10;
  overflow: hidden;
  cursor: pointer;
  color: #000; }

.flex-pauseplay a:before {
  font-family: "flexslider-icon";
  font-size: 20px;
  display: inline-block;
  content: '\f004'; }

.flex-pauseplay a:hover {
  opacity: 1; }

.flex-pauseplay a.flex-play:before {
  content: '\f003'; }

.flex-control-nav {
  width: 100%;
  position: absolute;
  bottom: 20px;
  text-align: center; }

.flex-control-nav li {
  margin: 0 6px;
  display: inline-block;
  zoom: 1;
  display: inline; }

.flex-control-paging {
  z-index: 1000; }

.flex-control-paging li a {
  width: 12px;
  height: 12px;
  display: inline-block;
  background: #fff;
  cursor: pointer;
  text-indent: -9999px;
  -moz-opacity: 0.5;
  -khtml-opacity: 0.5;
  -webkit-opacity: 0.5;
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=${opperc})";
  filter: "alpha(opacity=${opperc})";
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px; }

.flex-control-paging li a:hover {
  -moz-opacity: 1;
  -khtml-opacity: 1;
  -webkit-opacity: 1;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=${opperc})";
  filter: "alpha(opacity=${opperc})"; }

.flex-control-paging li a.flex-active {
  -moz-opacity: 1;
  -khtml-opacity: 1;
  -webkit-opacity: 1;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=${opperc})";
  filter: "alpha(opacity=${opperc})";
  cursor: default; }

.flex-control-thumbs {
  margin: 5px 0 0;
  position: static;
  overflow: hidden; }

.flex-control-thumbs li {
  width: 25%;
  float: left;
  margin: 0; }

.flex-control-thumbs img {
  width: 100%;
  height: auto;
  display: block;
  opacity: 0.7;
  cursor: pointer;
  -moz-user-select: none;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease; }

.flex-control-thumbs img:hover {
  opacity: 1; }

.flex-control-thumbs .flex-active {
  opacity: 1;
  cursor: default; }

/* ====================================================================================================================
 * RESPONSIVE
 * ====================================================================================================================*/
@media screen and (max-width: 860px) {
  .flex-direction-nav .flex-prev {
    opacity: 1;
    left: 10px; }
  .flex-direction-nav .flex-next {
    opacity: 1;
    right: 10px; } }

.alignnone {
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
  height: auto; }

.aligncenter {
  display: block;
  margin: 0.5rem auto;
  height: auto; }

.alignleft,
.alignright {
  margin-bottom: 0.5rem;
  height: auto; }

@media (min-width: 576px) {
  .alignleft {
    float: left;
    margin-right: 0.5rem; }
  .alignright {
    float: right;
    margin-left: 0.5rem; } }

/* =================================================
     Header
   ============================================== */
.header {
  padding: 10px 0;
  background-color: #115626;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 500;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out; }
  @media handheld, only screen and (max-width: 1000px) {
    .header {
      padding: 5px 0; } }
  html.hero .header {
    background-color: transparent; }
  html .header.scrollnav {
    background-color: rgba(17, 86, 33, 0.95); }
  @media (max-width: 575px) {
    .header .sm-col {
      display: none;
      border-bottom: 1px solid #D7DDD9;
      padding: 8px 0;
      margin-bottom: 5px;
      text-align: center; } }
  .header a.logo {
    width: 200px;
    display: block; }
    .header a.logo img {
      width: 100%;
      height: auto;
      display: block; }

div.wrap {
  clear: both; }

.wrap {
  clear: both;
  padding-top: 75px;
  /* Based on the height of the menu */ }
  @media handheld, only screen and (max-width: 1000px) {
    .wrap {
      padding-top: 65px;
      /* Based on the height of the menu */ } }

html.hero .wrap {
  padding-top: 0; }

.header.top {
  background-color: #fff; }

.headroom {
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out; }

.headroom--pinned {
  transform: translateY(0%); }

.headroom--unpinned {
  transform: translateY(-100%); }

@media handheld, only screen and (max-width: 1000px) {
  .headroom--pinned {
    transform: none; }
  .headroom--unpinned {
    transform: none; } }

.nav-primary {
  float: right;
  display: block;
  margin-right: -17px; }
  .nav-primary ul {
    padding: 0;
    margin: 0;
    list-style: none;
    margin-left: 50px;
    text-align: right; }
    .nav-primary ul li {
      float: left;
      margin: 0;
      z-index: 5001;
      padding: 7px 10px; }
      @media handheld, only screen and (min-width: 1250px) {
        .nav-primary ul li {
          padding: 7px 17px; } }
      .nav-primary ul li a:active, .nav-primary ul li a.nice-select.open,
      .nav-primary ul li a:hover,
      .nav-primary ul li a:link,
      .nav-primary ul li a:visited {
        display: block;
        padding: 7px 0 3px;
        margin: 0;
        -webkit-transition: color .2s ease-out;
        -moz-transition: color .2s ease-out;
        -o-transition: color .2s ease-out;
        transition: color .2s ease-out;
        font-family: "franklin-gothic-ext-comp-urw", sans-serif;
        /*
         * Menu font styles
         */
        font-size: 12px;
        letter-spacing: 0.02em;
        line-height: 200%;
        color: #fff;
        text-transform: uppercase;
        text-decoration: none; }
        html.wf-active .nav-primary ul li a:active, html.wf-active .nav-primary ul li a.nice-select.open, html.wf-active
        .nav-primary ul li a:hover, html.wf-active
        .nav-primary ul li a:link, html.wf-active
        .nav-primary ul li a:visited {
          font-size: 22px;
          line-height: 100%;
          text-transform: uppercase;
          letter-spacing: 0.08em; }
    .nav-primary ul li a:hover,
    .nav-primary ul li.active a:hover,
    .nav-primary ul li.hovered a {
      color: rgba(255, 255, 255, 0.9); }
    .nav-primary ul li.menu-item-has-children a::after {
      content: "";
      display: inline-block;
      vertical-align: top;
      width: 0;
      height: 0;
      margin-left: 2px;
      margin-top: -3px;
      margin-right: 0;
      vertical-align: middle;
      border-top: 4px solid;
      border-right: 4px solid transparent;
      border-left: 4px solid transparent; }
    .nav-primary ul li.menu-item-has-children {
      position: relative;
      -webkit-transition: all 0.2s ease-out;
      -moz-transition: all 0.2s ease-out;
      -o-transition: all 0.2s ease-out;
      transition: all 0.2s ease-out;
      /* Sub menu */ }
      .nav-primary ul li.menu-item-has-children ul.sub-menu::before {
        content: "";
        display: inline-block;
        vertical-align: top;
        width: 0;
        height: 0;
        margin-left: 2px;
        margin-top: -3px;
        margin-right: 0;
        vertical-align: middle;
        border-bottom: 8px solid #262626;
        border-right: 8px solid transparent;
        border-left: 8px solid transparent;
        position: absolute;
        z-index: 1001;
        left: 50%;
        margin-left: -10px;
        top: -4px; }
        @media handheld, only screen and (max-width: 1000px) {
          .nav-primary ul li.menu-item-has-children ul.sub-menu::before {
            position: relative;
            top: -16px;
            margin: 0;
            left: 40px;
            border-bottom: 8px solid #115626; } }
      .nav-primary ul li.menu-item-has-children ul.sub-menu {
        display: none;
        position: absolute;
        margin: 0;
        z-index: 1001;
        background-color: #262626;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
        width: 300px;
        left: 50%;
        margin-left: -150px;
        top: 30px;
        padding: 5px 0;
        -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        -moz-opacity: 0;
        -khtml-opacity: 0;
        -webkit-opacity: 0;
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=${opperc})";
        filter: "alpha(opacity=${opperc})";
        -webkit-transition: all 0.1s ease-out;
        -moz-transition: all 0.1s ease-out;
        -o-transition: all 0.1s ease-out;
        transition: all 0.1s ease-out; }
        @media handheld, only screen and (max-width: 1000px) {
          .nav-primary ul li.menu-item-has-children ul.sub-menu {
            position: relative;
            width: 100%;
            left: auto;
            margin: 0;
            padding: 0 0 20px 0;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            border-radius: 0;
            background-color: #115626; } }
        .nav-primary ul li.menu-item-has-children ul.sub-menu.hovered {
          display: block; }
        .nav-primary ul li.menu-item-has-children ul.sub-menu.inplace {
          top: 45px;
          -moz-opacity: 1;
          -khtml-opacity: 1;
          -webkit-opacity: 1;
          opacity: 1;
          -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=${opperc})";
          filter: "alpha(opacity=${opperc})"; }
        .nav-primary ul li.menu-item-has-children ul.sub-menu li {
          display: block;
          float: none;
          text-align: left;
          margin: 0;
          padding: 0; }
          .nav-primary ul li.menu-item-has-children ul.sub-menu li a::after {
            display: none; }
        .nav-primary ul li.menu-item-has-children ul.sub-menu a:active, .nav-primary ul li.menu-item-has-children ul.sub-menu a.nice-select.open,
        .nav-primary ul li.menu-item-has-children ul.sub-menu a:hover,
        .nav-primary ul li.menu-item-has-children ul.sub-menu a:link,
        .nav-primary ul li.menu-item-has-children ul.sub-menu a:visited {
          color: #fff;
          display: block;
          padding: 9px 17px;
          border: none !important;
          -webkit-transition: background-color .2s ease-out;
          -moz-transition: background-color .2s ease-out;
          -o-transition: background-color .2s ease-out;
          transition: background-color .2s ease-out; }
        .nav-primary ul li.menu-item-has-children ul.sub-menu a:hover {
          background-color: #0c3c1b;
          color: #fff; }
        @media handheld, only screen and (max-width: 1000px) {
          .nav-primary ul li.menu-item-has-children ul.sub-menu {
            display: block;
            position: static;
            width: 100%;
            height: 100%;
            text-align: left;
            display: none;
            margin-top: -10px; }
            .nav-primary ul li.menu-item-has-children ul.sub-menu a:active, .nav-primary ul li.menu-item-has-children ul.sub-menu a.nice-select.open,
            .nav-primary ul li.menu-item-has-children ul.sub-menu a:hover,
            .nav-primary ul li.menu-item-has-children ul.sub-menu a:link,
            .nav-primary ul li.menu-item-has-children ul.sub-menu a:visited {
              background: transparent; } }
      .nav-primary ul li.menu-item-has-children.left ul.sub-menu {
        left: auto;
        right: 0; }
  @media handheld, only screen and (max-width: 1000px) {
    .nav-primary {
      padding: 36px 0 0;
      text-align: left;
      z-index: 2001;
      position: fixed;
      background-color: #115626;
      width: 250px;
      right: -250px;
      top: 0;
      height: 100%;
      margin: 0;
      z-index: 8000;
      -webkit-transition: all 0.3s ease-out;
      -moz-transition: all 0.3s ease-out;
      -o-transition: all 0.3s ease-out;
      transition: all 0.3s ease-out;
      overflow: scroll; }
      .nav-primary ul {
        margin-left: 0;
        text-align: left; }
        .nav-primary ul li {
          display: block;
          float: none;
          margin: 0;
          padding: 0;
          border: 0;
          z-index: 5001; }
          .nav-primary ul li a:active, .nav-primary ul li a.nice-select.open,
          .nav-primary ul li a:hover,
          .nav-primary ul li a:link,
          .nav-primary ul li a:visited {
            text-decoration: none;
            display: block;
            padding: 18px;
            -webkit-transition: all 0.2s ease-out;
            -moz-transition: all 0.2s ease-out;
            -o-transition: all 0.2s ease-out;
            transition: all 0.2s ease-out;
            color: #fff;
            border: 0; }
          .nav-primary ul li a:hover {
            background-color: transparent; }
        .nav-primary ul li.active {
          background-image: none; }
        .nav-primary ul li.active a {
          background: transparent; }
        .nav-primary ul li a:hover,
        .nav-primary ul li.active a:active,
        .nav-primary ul li.active a.nice-select.open,
        .nav-primary ul li.active a:hover,
        .nav-primary ul li.active a:link,
        .nav-primary ul li.active a:visited,
        .nav-primary ul li.hovered a {
          color: #fff; }
        .nav-primary ul li.contact.active a:active, .nav-primary ul li.contact.active a.nice-select.open,
        .nav-primary ul li.contact.active a:hover,
        .nav-primary ul li.contact.active a:link,
        .nav-primary ul li.contact.active a:visited {
          color: #1a1a1a; } }

.nav-primary ul li.mobile-only {
  display: none; }

.nav-primary ul li.desktop-only {
  display: inline-block; }

@media handheld, only screen and (max-width: 1000px) {
  .nav-primary ul li.mobile-only {
    display: block; }
  .nav-primary ul li.desktop-only {
    display: none; } }

#mobile-menu {
  cursor: pointer;
  display: none;
  position: fixed;
  z-index: 9999;
  top: 11px;
  right: 30px; }
  @media (max-width: 575px) {
    #mobile-menu {
      right: 10px; } }
  #mobile-menu .tcon {
    background: transparent !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none !important;
    cursor: pointer;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    height: 50px;
    transition: 0.3s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 30px;
    background: transparent;
    outline: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-tap-highlight-color: transparent; }
  #mobile-menu .tcon > * {
    display: block; }
  #mobile-menu .tcon:focus,
  #mobile-menu .tcon:hover {
    outline: none;
    background: transparent; }
  #mobile-menu .tcon::-moz-focus-inner {
    border: 0; }
  #mobile-menu .tcon-menu__lines {
    display: inline-block;
    height: 2px;
    width: 30px;
    transition: 0.3s;
    background: #fff;
    position: relative; }
  #mobile-menu .tcon-menu__lines::after,
  #mobile-menu .tcon-menu__lines::before {
    display: inline-block;
    height: 2px;
    width: 30px;
    transition: 0.3s;
    background: #fff;
    content: '';
    position: absolute;
    left: 0;
    -webkit-transform-origin: 2.85714px center;
    transform-origin: 2.85714px center;
    width: 100%; }
  #mobile-menu .tcon-menu__lines::before {
    top: 10px; }
  #mobile-menu .tcon-menu__lines::after {
    top: -10px; }
  #mobile-menu .tcon-transform .tcon-menu__lines {
    -webkit-transform: scale3d(0.8, 0.8, 0.8);
    transform: scale3d(0.8, 0.8, 0.8); }
  #mobile-menu .tcon-menu--xcross {
    width: auto; }
  #mobile-menu .tcon-menu--xcross.tcon-transform .tcon-menu__lines {
    background: transparent; }
  #mobile-menu .tcon-menu--xcross.tcon-transform .tcon-menu__lines::after,
  #mobile-menu .tcon-menu--xcross.tcon-transform .tcon-menu__lines::before {
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    top: 0;
    width: 40px; }
  #mobile-menu .tcon-menu--xcross.tcon-transform .tcon-menu__lines::before {
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    background: #fff; }
  #mobile-menu .tcon-menu--xcross.tcon-transform .tcon-menu__lines::after {
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    background: #fff; }
  #mobile-menu .tcon-visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }
  #mobile-menu .tcon-visuallyhidden:active, #mobile-menu .tcon-visuallyhidden.nice-select.open,
  #mobile-menu .tcon-visuallyhidden:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto; }

@media handheld, only screen and (max-width: 1000px) {
  #mobile-menu {
    display: block;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out; }
  .wrapper {
    -webkit-transition: right 0.2s ease-in-out;
    -moz-transition: right 0.2s ease-in-out;
    -o-transition: right 0.2s ease-in-out;
    transition: right 0.2s ease-in-out;
    position: absolute;
    right: 0; }
  .wrapper.with-transitions .footer,
  .wrapper.with-transitions .h-bar,
  .wrapper.with-transitions .logo,
  .wrapper.with-transitions main {
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out; }
  .wrapper.menu-open .nav-primary {
    right: 0;
    -webkit-box-shadow: -25px 0 100px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: -25px 0 100px rgba(0, 0, 0, 0.4);
    box-shadow: -25px 0 100px rgba(0, 0, 0, 0.4); } }

.footer.footer {
  margin: 0; }
  .footer.footer .footer-inner {
    padding-top: 40px;
    padding-bottom: 40px; }
  .footer.footer, .footer.footer p, .footer.footer ul li {
    font-size: 14px; }
  .footer.footer, .footer.footer p, .footer.footer h3, .footer.footer h4, .footer.footer h5, .footer.footer ul li {
    color: #fff; }
  .footer.footer p {
    margin: 0; }
  .footer.footer a.butt {
    padding: 12px; }
  @media handheld, only screen and (max-width: 768px) {
    .footer.footer .left {
      text-align: center; } }
  .footer.footer .right {
    text-align: right; }
    .footer.footer .right .icon-pin {
      padding-top: 30px; }
    @media handheld, only screen and (max-width: 768px) {
      .footer.footer .right {
        text-align: center; } }
  .footer.footer .sn {
    margin-bottom: 20px;
    -webkit-box-pack: justify;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: justify;
    -moz-box-pack: justify;
    justify-content: flex-end; }
    @media handheld, only screen and (max-width: 768px) {
      .footer.footer .sn {
        -webkit-box-pack: justify;
        -webkit-justify-content: center;
        -ms-flex-pack: justify;
        -moz-box-pack: justify;
        justify-content: center; } }
  .footer.footer .legal {
    font-size: 14px;
    text-align: center; }
    .footer.footer .legal, .footer.footer .legal a {
      color: rgba(0, 0, 0, 0.3); }

.page-heading {
  margin: 50px 0; }
  .page-heading .col {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -moz-box-wrap: nowrap;
    -webkit-box-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    -ms-flexbox-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: center;
    -ms-flex-pack: justify;
    -moz-box-pack: justify;
    justify-content: center; }
    .page-heading .col h1 {
      font-family: "franklin-gothic-ext-comp-urw", sans-serif;
      font-size: 24px;
      line-height: 100%;
      text-transform: uppercase;
      letter-spacing: 0.08em;
      margin: 0; }
    .page-heading .col span {
      height: 50px;
      width: 1px;
      border-left: 1px solid #115626;
      margin: 0 20px; }
    .page-heading .col h2 {
      font-size: 34px;
      margin: 0; }
    @media handheld, only screen and (max-width: 767px) {
      .page-heading .col {
        display: block; }
        .page-heading .col span {
          width: 50px;
          height: 1px;
          border: 0;
          border-top: 1px solid #115626;
          margin: 20px 0;
          display: block; } }

body.single-lab_portfolio .page-title {
  background-color: #1a1a1a; }
  body.single-lab_portfolio .page-title .overlay {
    background: rgba(0, 0, 0, 0.25); }

.page-title {
  background: rgba(6, 42, 61, 0.9) url("../../assets/images/loading.gif") center center no-repeat;
  background-size: 60px 60px;
  clear: both; }
  .page-title .overlay {
    height: 450px;
    text-align: center;
    background: rgba(6, 42, 61, 0.25);
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -moz-box-wrap: nowrap;
    -webkit-box-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    -ms-flexbox-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    .page-title .overlay.no-content {
      background: transparent; }
    .page-title .overlay .flex-container {
      margin: 0 auto; }
    .page-title .overlay h1,
    .page-title .overlay h2,
    .page-title .overlay h3,
    .page-title .overlay h4,
    .page-title .overlay p {
      color: #fff; }
    .page-title .overlay h1 {
      margin: 0 25px;
      padding: 0;
      font-weight: 200; }
    .page-title .overlay p {
      margin: 15px 25px 0; }

html .page-title.inplace {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; }

.linear-action .row div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -moz-box-wrap: nowrap;
  -webkit-box-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  -ms-flexbox-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: center;
  -ms-flex-pack: justify;
  -moz-box-pack: justify;
  justify-content: center; }
  @media (max-width: 991px) {
    .linear-action .row div {
      display: block; } }
  .linear-action .row div h3 {
    margin-right: 25px; }
    @media (max-width: 991px) {
      .linear-action .row div h3 {
        margin-right: 0; } }

section.featured {
  padding: 80px 0;
  clear: both;
  text-align: center;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+49,ffffff+53,ffffff+100 */
  background: #F2F9F2;
  /* Old browsers */
  background: -moz-linear-gradient(left, #F2F9F2 0%, #F2F9F2 50%, #1a1a1a 50%, #1a1a1a 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #F2F9F2 0%, #F2F9F2 50%, #1a1a1a 50%, #1a1a1a 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #F2F9F2 0%, #F2F9F2 50%, #1a1a1a 50%, #1a1a1a 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */ }
  @media (max-width: 767px) {
    section.featured {
      padding: 80px 0 0; } }
  section.featured.flipped {
    background: #F2F9F2;
    /* Old browsers */
    background: -moz-linear-gradient(left, #1a1a1a 0%, #1a1a1a 50%, #F2F9F2 50%, #F2F9F2 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #1a1a1a 0%, #1a1a1a 50%, #F2F9F2 50%, #F2F9F2 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #1a1a1a 0%, #1a1a1a 50%, #F2F9F2 50%, #F2F9F2 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */ }
    @media (max-width: 767px) {
      section.featured.flipped {
        padding-bottom: 80px; } }
  @media (max-width: 767px) {
    section.featured, section.featured.flipped {
      background: transparent; } }
  section.featured a.butt {
    padding: 15px 30px;
    border: 10px solid #2b2b2b; }
    @media (max-width: 767px) {
      section.featured a.butt {
        border: none; } }
  section.featured .row {
    padding-left: 0;
    padding-right: 0; }
  section.featured .col-md-6 {
    padding-left: 50px;
    padding-right: 50px; }
    section.featured .col-md-6 p {
      margin: 0; }
    section.featured .col-md-6 img {
      max-width: 130px;
      margin-bottom: 40px; }

section.page-title-alt {
  text-align: center;
  background: -moz-linear-gradient(top, #1a1a1a 0%, #1a1a1a 50%, #fff 50%, #fff 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #1a1a1a 0%, #1a1a1a 50%, #fff 50%, #fff 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #1a1a1a 0%, #1a1a1a 50%, #fff 50%, #fff 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  padding-top: 60px; }
  section.page-title-alt h1 {
    color: #fff;
    font-size: 26px;
    line-height: 130%;
    font-style: normal; }
    html.wf-active section.page-title-alt h1 {
      font-size: 30px;
      line-height: 100%;
      text-transform: uppercase;
      letter-spacing: 0.02em; }

.entry-content section:first-child p:first-child::first-letter {
  font-family: "franklin-gothic-ext-comp-urw", sans-serif;
  font-size: 70px;
  height: 70px;
  line-height: 90%;
  float: left;
  color: #262626;
  margin-right: 5px;
  margin-top: 4px; }

article.post time, article.post p.author {
  display: inline-block;
  font-size: 14px;
  color: #B5B5B5; }

article.post span.del {
  color: #B5B5B5;
  -moz-opacity: 0.5;
  -khtml-opacity: 0.5;
  -webkit-opacity: 0.5;
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=${opperc})";
  filter: "alpha(opacity=${opperc})"; }

article.post header {
  padding: 15px;
  text-align: center; }

section.blog-filters {
  margin-bottom: 50px; }
  section.blog-filters .cats select {
    width: 100%; }
  section.blog-filters .search-form label {
    padding: 0;
    margin: 0; }
    section.blog-filters .search-form label input.search-field {
      padding: 8px !important;
      font-size: 16px;
      margin: 0;
      width: auto !important;
      display: block !important;
      box-sizing: border-box; }
  section.blog-filters .search-form .search-submit {
    display: none; }

.posts-cat-focus h3 {
  text-transform: none;
  letter-spacing: 0;
  font-size: 18px; }

.posts-cat-focus img.thumb {
  width: 100%;
  margin-bottom: 15px; }

.posts-cat-focus .row .row {
  margin: 0 -25px;
  padding: 0; }

.post-list .row .col-md-6 {
  border-right: 1px solid #D7DDD9;
  border-bottom: 1px solid #D7DDD9; }

@media (min-width: 992px) {
  .post-list .row .col-md-6 {
    border-right: 1px solid #D7DDD9;
    border-bottom: 1px solid #D7DDD9; }
  .post-list .row .col-md-6:nth-child(3n) {
    border-right: none; }
  .post-list .row .col-md-6:nth-last-child(-n+3) {
    border-bottom: none; } }

@media (max-width: 991px) {
  .post-list .row .col-md-6 {
    border-right: 1px solid #D7DDD9;
    border-bottom: 1px solid #D7DDD9; }
  .post-list .row .col-md-6:nth-child(2n) {
    border-right: none; }
  .post-list .row .col-md-6:nth-last-child(-n+2) {
    border-bottom: none; } }

@media (max-width: 767px) {
  .post-list .row .col-md-6 {
    border-right: 1px solid #D7DDD9;
    border-bottom: 1px solid #D7DDD9; }
  .post-list .row .col-md-6 {
    border-right: none; }
  .post-list .row .col-md-6:last-child {
    border-bottom: none; } }

.post-list article.post {
  heght: 100%;
  display: block; }
  .post-list article.post header {
    min-height: 70px;
    padding-top: 15px; }
    .post-list article.post header h2.entry-title {
      font-size: 19px;
      line-height: 150%;
      margin: 0 0 10px 0; }
      html.wf-active .post-list article.post header h2.entry-title {
        font-size: 25px;
        line-height: 120%; }
  .post-list article.post .entry-summary {
    padding-bottom: 15px; }

.post-list .row {
  padding: 0; }
  .post-list .row .col-md-6 {
    border: 0; }

.post-list .post-block {
  padding: 0;
  background-size: cover;
  background-position: center center;
  height: 300px;
  text-align: left; }
  .post-list .post-block a.article {
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    padding: 10px 10px 10px;
    margin: 0;
    display: block;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -moz-box-wrap: nowrap;
    -webkit-box-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    -ms-flexbox-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: flex-end;
    -webkit-align-items: flex-end;
    -moz-box-align: flex-end;
    -ms-flex-align: flex-end;
    align-items: flex-end; }
    .post-list .post-block a.article header {
      text-align: left; }
      .post-list .post-block a.article header b {
        font-weight: 400;
        font-size: 17px; }
    .post-list .post-block a.article:hover {
      background: rgba(0, 0, 0, 0.1); }
    .post-list .post-block a.article, .post-list .post-block a.article h2 {
      color: #fff; }
    .post-list .post-block a.article h2 {
      margin: 0;
      padding: 0; }

.entry-share ul.entry-share-btns li.entry-share-btn a {
  background: #7D8491;
  color: #fff; }
  .entry-share ul.entry-share-btns li.entry-share-btn a:hover {
    background: #115626; }

section.social-media-share {
  margin-top: 100px;
  margin-bottom: 80px;
  text-align: center; }

.pager {
  background: #f1f6fa; }
  .pager .row {
    padding: 0; }
    .pager .row nav.posts-navigation .nav-links {
      width: 100%;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -moz-box-wrap: nowrap;
      -webkit-box-wrap: nowrap;
      -webkit-flex-wrap: nowrap;
      -ms-flexbox-wrap: nowrap;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      align-items: center; }
      .pager .row nav.posts-navigation .nav-links div {
        width: 50%; }
        .pager .row nav.posts-navigation .nav-links div a {
          display: block;
          padding: 50px;
          background: #1a1a1a;
          text-align: center;
          font-family: "franklin-gothic-ext-comp-urw", sans-serif;
          color: #fff;
          font-size: 22px; }
          .pager .row nav.posts-navigation .nav-links div a:hover {
            background: #115626; }
          @media (max-width: 767px) {
            .pager .row nav.posts-navigation .nav-links div a {
              font-size: 19px;
              padding: 30px 20px; } }

body#tinymce {
  margin: 12px !important; }

html .woocommerce ul.products {
  background: #212121;
  margin: 0 -75px; }
  @media handheld, only screen and (max-width: 1000px) {
    html .woocommerce ul.products {
      margin: 0 -50px; } }
  @media handheld, only screen and (max-width: 768px) {
    html .woocommerce ul.products {
      margin: 0 -30px; } }
  html .woocommerce ul.products li.product {
    width: 50%;
    margin: 0 !important;
    padding: 0 !important; }
    @media handheld, only screen and (max-width: 768px) {
      html .woocommerce ul.products li.product {
        width: 100% !important;
        display: block;
        float: none; } }
    html .woocommerce ul.products li.product a {
      padding: 0 !important;
      margin: 0 !important;
      background: #1a1a1a;
      display: block; }
      html .woocommerce ul.products li.product a img {
        padding: 0;
        margin: 0;
        -moz-opacity: 0.7;
        -khtml-opacity: 0.7;
        -webkit-opacity: 0.7;
        opacity: 0.7;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=${opperc})";
        filter: "alpha(opacity=${opperc})";
        border: none !important; }
        html .woocommerce ul.products li.product a img:hover {
          -moz-opacity: 1;
          -khtml-opacity: 1;
          -webkit-opacity: 1;
          opacity: 1;
          -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=${opperc})";
          filter: "alpha(opacity=${opperc})"; }
    html .woocommerce ul.products li.product h2, html .woocommerce ul.products li.product .price, html .woocommerce ul.products li.product .button {
      display: none; }

html .woocommerce {
  padding-bottom: 60px; }
  html .woocommerce .col-1 {
    width: 100%; }
  html .woocommerce table.variations tbody tr td label {
    margin: 15px 0 0 0;
    padding: 0; }
  html .woocommerce table.variations tbody tr td .reset_variations {
    display: none; }
  html .woocommerce table.variations tbody tr td.label {
    text-transform: uppercase;
    letter-spacing: .04em;
    font-size: 16px; }

html .woocommerce {
  /* Add to cart */ }
  html .woocommerce a.add_to_cart_button,
  html .woocommerce a.button,
  html .woocommerce a.button.alt,
  html .woocommerce a.wc-forward,
  html .woocommerce button.button,
  html .woocommerce input.button,
  html .woocommerce input.button.checkout-button,
  html .woocommerce form.cart button.add_to_cart_button,
  html .woocommerce form.cart button.single_add_to_cart_button {
    display: inline-block;
    text-decoration: none;
    cursor: pointer;
    border: none;
    padding: 14px 15px;
    -webkit-transition: all 0.2s ease-in-out ease-out;
    -moz-transition: all 0.2s ease-in-out ease-out;
    -o-transition: all 0.2s ease-in-out ease-out;
    transition: all 0.2s ease-in-out ease-out;
    font-weight: 400;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0.2em;
    background-color: #115626 !important;
    color: #fff !important;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    background-image: none;
    text-shadow: none; }
  html .woocommerce button.button:disabled,
  html .woocommerce button.button[disabled],
  html .woocommerce input.button:disabled,
  html .woocommerce input.button[disabled] {
    background: rgba(0, 0, 0, 0.3) !important;
    color: #fff !important;
    border-color: rgba(0, 0, 0, 0.3) !important; }
  html .woocommerce a.add_to_cart_button:hover,
  html .woocommerce a.button:hover,
  html .woocommerce a.wc-forward:hover,
  html .woocommerce button.button:hover,
  html .woocommerce input.button,
  html .woocommerce input.button.checkout-button:hover,
  html .woocommerce input.button:hover,
  html .woocommerce form.cart button.add_to_cart_button:hover,
  html .woocommerce form.cart button.single_add_to_cart_button:hover {
    background-color: #7D8491 !important;
    color: #fff;
    background-color: #115626 !important;
    border-color: #115626 !important;
    color: #fff !important;
    background-image: none;
    text-shadow: none; }
  html .woocommerce a.button.alt.checkout-button {
    display: block; }
  html .woocommerce a.button.alt.checkout-button:hover {
    background-color: #115626; }
  html .woocommerce button.single_add_to_cart_button.alt {
    background-color: #115626;
    background-image: none;
    text-shadow: none;
    font-family: "proxima-nova", sans-serif;
    color: #fff;
    padding: 12.5px;
    border: 0;
    padding: 17px;
    background: transparent !important;
    color: #115626 !important; }
    html .woocommerce button.single_add_to_cart_button.alt:hover {
      background: #7D8491; }
  html .woocommerce input.button.alt {
    display: inline-block;
    text-decoration: none;
    cursor: pointer;
    border: none;
    padding: 14px 15px;
    -webkit-transition: all 0.2s ease-in-out ease-out;
    -moz-transition: all 0.2s ease-in-out ease-out;
    -o-transition: all 0.2s ease-in-out ease-out;
    transition: all 0.2s ease-in-out ease-out;
    font-weight: 400;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0.2em;
    background-color: #115626 !important;
    color: #fff !important;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    background: #115626;
    background-image: none;
    text-shadow: none;
    padding: 30px 40px;
    border: none;
    color: #fff; }
  html .woocommerce input.button.alt:hover {
    background-color: #7D8491 !important;
    color: #fff;
    background-image: none;
    text-shadow: none;
    background: #1a1a1a; }
  html .woocommerce input#coupon_code {
    padding: 10px !important; }

/*
.woocommerce .woocommerce-message, .woocommerce .woocommerce-error, .woocommerce .woocommerce-info {
  @include rounded(3px);
  background-image: none;
  text-shadow: none;
  @include box-shadow(none);
  @include inner-shadow(none);
  border: 1px solid $color-body-on-super-subtle;
  &:before {
    font-family: $font-serif;
    text-shadow: none;
    padding-top:1em;
    @include inner-shadow(0);
  }
}
.woocommerce .woocommerce-info {
  &:before {
    background-color: $color-accent;
  }
}
*/
.woocommerce .woocommerce-error,
.woocommerce .woocommerce-info,
.woocommerce .woocommerce-message {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  background-image: none;
  text-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  -webkit-box-shadow: inset none 1px 2px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: inset none 1px 2px rgba(0, 0, 0, 0.4);
  box-shadow: inset none 1px 2px rgba(0, 0, 0, 0.4);
  border-color: #115626; }
  .woocommerce .woocommerce-error:before,
  .woocommerce .woocommerce-info:before,
  .woocommerce .woocommerce-message:before {
    text-shadow: none;
    border: 0; }

.woocommerce .woocommerce-info:before {
  color: #115626; }

/* Payment box - appears on checkout and page page */
#payment {
  background: transparent;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px; }
  #payment ul.payment_methods {
    text-align: left;
    padding: 1em;
    border-bottom: 0;
    margin: 0;
    list-style: none outside; }
    #payment ul.payment_methods div.payment_box {
      position: relative;
      width: 96%;
      padding: 1em 2%;
      margin: 0.5em 0 1.5em 1.5em;
      font-size: 0.92em;
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      border-radius: 3px;
      line-height: 1.5em;
      background-image: none;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      color: #1a1a1a;
      text-shadow: none; }
    #payment ul.payment_methods:after {
      content: "";
      display: block;
      border: 0;
      position: absolute;
      top: 0;
      left: 0;
      margin: 0; }

/* Fix checkboxes. */
input.input-checkbox {
  background-color: black;
  display: inline-block; }

.woocommerce label.checkbox {
  display: inline-block;
  margin: 0;
  padding: 0; }

.woocommerce p.woocommerce-result-count {
  color: #B5B5B5;
  margin-bottom: 2em;
  font-size: 14px; }

.woocommerce ul.products li.product {
  text-align: center; }
  .woocommerce ul.products li.product a h2.woocommerce-loop-product__title {
    line-height: 110%;
    font-family: "franklin-gothic-ext-comp-urw", sans-serif;
    text-transform: none;
    letter-spacing: 0;
    font-size: 24px; }
  .woocommerce ul.products li.product img.attachment-shop_catalog {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    -webkit-box-shadow: inset none 1px 2px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: inset none 1px 2px rgba(0, 0, 0, 0.4);
    box-shadow: inset none 1px 2px rgba(0, 0, 0, 0.4);
    border: 1px solid #D7DDD9;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out; }
  .woocommerce ul.products li.product mark.count {
    background: transparent;
    color: #7D8491; }
  .woocommerce ul.products li.product span.price {
    font-size: 19px;
    color: #1a1a1a; }
  .woocommerce ul.products li.product .ajax_add_to_cart {
    margin-bottom: 5px; }
  .woocommerce ul.products li.product .sku {
    color: #B5B5B5; }

.woocommerce .woocommerce-billing-fields input,
html body .woocommerce input.input-text,
html body .woocommerce table.shop_table .input-text,
html body .woocommerce textarea.input-text,
input.input-text {
  margin: 0;
  box-sizing: border-box;
  padding: 9px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  font-size: 16px;
  color: #1a1a1a;
  line-height: 130%;
  -webkit-box-shadow: inset none 1px 2px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: inset none 1px 2px rgba(0, 0, 0, 0.4);
  box-shadow: inset none 1px 2px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border: 1px solid #D7DDD9;
  background-color: transparent;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out; }

html body .woocommerce #payment ul.payment_methods input.input-radio,
html body .woocommerce input.input-radio {
  border: 0; }

html body .woocommerce table.shop_table .coupon .input-text {
  min-width: 150px; }

html body .woocommerce table.shop_table .button {
  padding: 10px 18px; }

html body .woocommerce .form-row.form-row-wide span {
  padding: 0; }

html body .woocommerce ul#shipping_method li label span.woocommerce-Price-amount {
  float: none;
  position: static;
  text-align: left;
  display: inline-block;
  width: auto;
  min-width: 0;
  padding: 0;
  text-indent: 0; }

html body .woocommerce ul#shipping_method li label span.woocommerce-Price-currencySymbol {
  float: none;
  position: static;
  text-align: left;
  display: inline-block;
  width: auto;
  min-width: 0;
  padding: 0;
  margin: 0;
  text-indent: 0; }

html body .woocommerce #payment,
html body .woocommerce #add_payment_method #payment,
html body .woocommerce .woocommerce-MyAccount-content #payment,
html body .woocommerce .woocommerce-MyAccount-content #add_payment_method #payment {
  background-color: #F7F6F7; }
  html body .woocommerce #payment ul.payment_methods,
  html body .woocommerce #add_payment_method #payment ul.payment_methods,
  html body .woocommerce .woocommerce-MyAccount-content #payment ul.payment_methods,
  html body .woocommerce .woocommerce-MyAccount-content #add_payment_method #payment ul.payment_methods {
    width: auto;
    background-color: #fff;
    border: 1px solid #D7DDD9; }
    html body .woocommerce #payment ul.payment_methods div.payment_box,
    html body .woocommerce #payment ul.payment_methods div.woocommerce-PaymentBox.payment_box,
    html body .woocommerce #add_payment_method #payment ul.payment_methods div.payment_box,
    html body .woocommerce #add_payment_method #payment ul.payment_methods div.woocommerce-PaymentBox.payment_box,
    html body .woocommerce .woocommerce-MyAccount-content #payment ul.payment_methods div.payment_box,
    html body .woocommerce .woocommerce-MyAccount-content #payment ul.payment_methods div.woocommerce-PaymentBox.payment_box,
    html body .woocommerce .woocommerce-MyAccount-content #add_payment_method #payment ul.payment_methods div.payment_box,
    html body .woocommerce .woocommerce-MyAccount-content #add_payment_method #payment ul.payment_methods div.woocommerce-PaymentBox.payment_box {
      width: auto;
      background-color: #F7F6F7;
      margin: 20px 0 0; }
      html body .woocommerce #payment ul.payment_methods div.payment_box:before,
      html body .woocommerce #payment ul.payment_methods div.woocommerce-PaymentBox.payment_box:before,
      html body .woocommerce #add_payment_method #payment ul.payment_methods div.payment_box:before,
      html body .woocommerce #add_payment_method #payment ul.payment_methods div.woocommerce-PaymentBox.payment_box:before,
      html body .woocommerce .woocommerce-MyAccount-content #payment ul.payment_methods div.payment_box:before,
      html body .woocommerce .woocommerce-MyAccount-content #payment ul.payment_methods div.woocommerce-PaymentBox.payment_box:before,
      html body .woocommerce .woocommerce-MyAccount-content #add_payment_method #payment ul.payment_methods div.payment_box:before,
      html body .woocommerce .woocommerce-MyAccount-content #add_payment_method #payment ul.payment_methods div.woocommerce-PaymentBox.payment_box:before {
        content: "";
        display: block;
        border: 1em solid #F7F6F7;
        /* arrow size / color */
        border-right-color: transparent;
        border-left-color: transparent;
        border-top-color: transparent;
        position: absolute;
        top: -.75em;
        left: 0;
        margin: -1em 0 0 2em; }
      html body .woocommerce #payment ul.payment_methods div.payment_box fieldset,
      html body .woocommerce #payment ul.payment_methods div.woocommerce-PaymentBox.payment_box fieldset,
      html body .woocommerce #add_payment_method #payment ul.payment_methods div.payment_box fieldset,
      html body .woocommerce #add_payment_method #payment ul.payment_methods div.woocommerce-PaymentBox.payment_box fieldset,
      html body .woocommerce .woocommerce-MyAccount-content #payment ul.payment_methods div.payment_box fieldset,
      html body .woocommerce .woocommerce-MyAccount-content #payment ul.payment_methods div.woocommerce-PaymentBox.payment_box fieldset,
      html body .woocommerce .woocommerce-MyAccount-content #add_payment_method #payment ul.payment_methods div.payment_box fieldset,
      html body .woocommerce .woocommerce-MyAccount-content #add_payment_method #payment ul.payment_methods div.woocommerce-PaymentBox.payment_box fieldset {
        border: 0;
        padding: 0;
        margin: 0; }
      html body .woocommerce #payment ul.payment_methods div.payment_box .input-text,
      html body .woocommerce #payment ul.payment_methods div.woocommerce-PaymentBox.payment_box .input-text,
      html body .woocommerce #add_payment_method #payment ul.payment_methods div.payment_box .input-text,
      html body .woocommerce #add_payment_method #payment ul.payment_methods div.woocommerce-PaymentBox.payment_box .input-text,
      html body .woocommerce .woocommerce-MyAccount-content #payment ul.payment_methods div.payment_box .input-text,
      html body .woocommerce .woocommerce-MyAccount-content #payment ul.payment_methods div.woocommerce-PaymentBox.payment_box .input-text,
      html body .woocommerce .woocommerce-MyAccount-content #add_payment_method #payment ul.payment_methods div.payment_box .input-text,
      html body .woocommerce .woocommerce-MyAccount-content #add_payment_method #payment ul.payment_methods div.woocommerce-PaymentBox.payment_box .input-text {
        margin: 0;
        box-sizing: border-box;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        border: 1px solid #D7DDD9;
        background-color: #fff; }
  html body .woocommerce #payment .form-row,
  html body .woocommerce #add_payment_method #payment .form-row,
  html body .woocommerce .woocommerce-MyAccount-content #payment .form-row,
  html body .woocommerce .woocommerce-MyAccount-content #add_payment_method #payment .form-row {
    background: transparent; }

html body .woocommerce #payment div.form-row.place-order {
  padding-top: 40px;
  background-color: #fff; }

.woocommerce a.about_paypal {
  width: auto;
  padding: 0;
  margin: 0 0 0 10px;
  line-height: 100%; }

.woocommerce div.cart-totals table {
  padding-right: 30px;
  background-color: black; }

html body .woocommerce div.cart-collaterals table tr td span {
  padding-left: 50px; }

/* Register and login boxes */
.woocommerce form.checkout_coupon,
.woocommerce form.login,
.woocommerce form.register {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.woocommerce div.create-account {
  margin-top: 30px;
  margin-bottom: 30px; }

.woocommerce table.shop_table {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.woocommerce p.lost_password {
  margin-top: 20px; }

.woocommerce .login input.button {
  margin-right: 20px; }

.woocommerce h3#order_review_heading {
  margin-top: 50px;
  padding-top: 50px;
  border-top: 1px solid #D7DDD9; }

.single-product h1 {
  text-align: left; }

.woocommerce a.shipping-calculator-button {
  margin-top: 20px;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  border: none;
  padding: 14px 15px;
  -webkit-transition: all 0.2s ease-in-out ease-out;
  -moz-transition: all 0.2s ease-in-out ease-out;
  -o-transition: all 0.2s ease-in-out ease-out;
  transition: all 0.2s ease-in-out ease-out;
  font-weight: 400;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0.2em;
  background-color: #115626 !important;
  color: #fff !important;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }
  .woocommerce a.shipping-calculator-button:hover {
    background-color: #7D8491 !important;
    color: #fff; }

.woocommerce nav.woocommerce-pagination {
  border: 0;
  margin-bottom: 50px; }
  .woocommerce nav.woocommerce-pagination ul.page-numbers {
    border: 0; }
    .woocommerce nav.woocommerce-pagination ul.page-numbers li {
      border: 0; }
      .woocommerce nav.woocommerce-pagination ul.page-numbers li span.current {
        background: #1a1a1a;
        color: #fff; }
      .woocommerce nav.woocommerce-pagination ul.page-numbers li a {
        border: 0; }
        .woocommerce nav.woocommerce-pagination ul.page-numbers li a:hover {
          background: #7D8491;
          color: #fff; }

.woocommerce .cat-header-container {
  padding-bottom: 40px; }
  @media handheld, only screen and (max-width: 768px) {
    .woocommerce .cat-header-container {
      padding-bottom: 20px; } }
  .woocommerce .cat-header-container .cat-image {
    float: left;
    padding-right: 30px; }
    @media handheld, only screen and (max-width: 768px) {
      .woocommerce .cat-header-container .cat-image {
        float: none;
        padding: 0 0 20px; } }
    .woocommerce .cat-header-container .cat-image img {
      width: 200px;
      height: auto;
      border: 1px solid #D7DDD9; }

.woocommerce nav.woocommerce-MyAccount-navigation {
  padding-right: 40px; }
  .woocommerce nav.woocommerce-MyAccount-navigation ul,
  .woocommerce nav.woocommerce-MyAccount-navigation ul li {
    margin: 0;
    padding: 0;
    list-style: none; }
  .woocommerce nav.woocommerce-MyAccount-navigation ul li {
    border-bottom: 1px solid #D7DDD9; }
    .woocommerce nav.woocommerce-MyAccount-navigation ul li a {
      display: block;
      padding: 6px;
      color: #262626; }
      .woocommerce nav.woocommerce-MyAccount-navigation ul li a:hover {
        color: #115626; }
    .woocommerce nav.woocommerce-MyAccount-navigation ul li.is-active {
      background: #115626; }
      .woocommerce nav.woocommerce-MyAccount-navigation ul li.is-active a {
        color: #fff; }

.woocommerce .summary.entry-summary,
.woocommerce .summary.entry-summary p {
  font-size: 16px; }

.woocommerce .summary.entry-summary h1.product_title {
  margin: 0 0 5px;
  font-size: 34px; }

.woocommerce .summary.entry-summary p.price {
  font-size: 22px;
  color: #1a1a1a;
  margin: 0 0 15px; }

.related.products {
  clear: both;
  padding-top: 100px; }

.woocommerce .images img {
  border: 1px solid #D7DDD9; }

.woocommerce h2 {
  color: #1a1a1a; }

.woocommerce .box.store-notice {
  text-align: center;
  display: inline-block;
  margin: 0 auto;
  color: #1a1a1a;
  border: 1px solid #115626;
  margin-bottom: 20px;
  font-family: "franklin-gothic-ext-comp-urw", sans-serif; }

.woocommerce .store-header {
  text-align: center;
  display: block;
  margin: 0 auto 50px; }
  .woocommerce .store-header .links-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -moz-box-wrap: nowrap;
    -webkit-box-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    -ms-flexbox-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: center;
    -ms-flex-pack: justify;
    -moz-box-pack: justify;
    justify-content: center; }
  @media handheld, only screen and (max-width: 768px) {
    .woocommerce .store-header {
      text-align: center;
      background: transparent;
      padding: 0; }
      .woocommerce .store-header .links-container {
        border: 1px solid #D7DDD9;
        padding: 6px;
        display: block; } }
  .woocommerce .store-header nav.woocommerce-breadcrumb {
    padding: 0;
    margin: 0;
    color: rgba(0, 0, 0, 0.7); }
    .woocommerce .store-header nav.woocommerce-breadcrumb a {
      color: rgba(0, 0, 0, 0.7);
      border: 0;
      font-weight: 500; }
    .woocommerce .store-header nav.woocommerce-breadcrumb span {
      font-weight: 300;
      color: rgba(0, 0, 0, 0.15);
      padding: 0 1px; }
    @media handheld, only screen and (max-width: 768px) {
      .woocommerce .store-header nav.woocommerce-breadcrumb {
        float: none;
        margin-bottom: 10px; } }
  .woocommerce .store-header a.cart {
    display: block;
    background: url(../../assets/images/shop-menu-sprites.png) 0 3px no-repeat;
    background-size: 25px auto;
    padding: 5px 0 5px 32px;
    margin: 0 15px;
    line-height: 100%;
    font-size: 16px;
    margin-left: 25px;
    color: #1a1a1a; }
    .woocommerce .store-header a.cart:hover {
      -moz-opacity: 0.8;
      -khtml-opacity: 0.8;
      -webkit-opacity: 0.8;
      opacity: 0.8;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=${opperc})";
      filter: "alpha(opacity=${opperc})"; }
    @media handheld, only screen and (max-width: 768px) {
      .woocommerce .store-header a.cart {
        float: none;
        display: inline-block;
        margin-left: 0;
        margin-right: 15px; } }
  .woocommerce .store-header a.my-account {
    display: block;
    float: right;
    background: url(../../assets/images/shop-menu-sprites.png) 0 -74px no-repeat;
    background-size: 25px auto;
    padding: 5px 0 5px 31px;
    margin: 0 15px;
    line-height: 100%;
    font-size: 16px;
    color: #1a1a1a; }
    .woocommerce .store-header a.my-account:hover {
      -moz-opacity: 0.8;
      -khtml-opacity: 0.8;
      -webkit-opacity: 0.8;
      opacity: 0.8;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=${opperc})";
      filter: "alpha(opacity=${opperc})"; }
    @media handheld, only screen and (max-width: 768px) {
      .woocommerce .store-header a.my-account {
        float: none;
        display: inline-block; } }

.woocommerce .checkout .row {
  margin: 0 -15px;
  padding: 0; }

.sn {
  font-size: 0px; }
  .svg .sn {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -moz-box-wrap: nowrap;
    -webkit-box-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    -ms-flexbox-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
  .sn a {
    background: url("../../assets/images/sn-sprites.png") center center repeat-y;
    background-size: 20px auto;
    margin: 0;
    display: inline-block;
    border: 0;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    -moz-opacity: 1;
    -khtml-opacity: 1;
    -webkit-opacity: 1;
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=${opperc})";
    filter: "alpha(opacity=${opperc})";
    text-decoration: none;
    margin: 0 8px;
    -moz-opacity: 0.6;
    -khtml-opacity: 0.6;
    -webkit-opacity: 0.6;
    opacity: 0.6;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=${opperc})";
    filter: "alpha(opacity=${opperc})"; }
    .sn a svg {
      display: none; }
    .svg .sn a {
      -moz-opacity: 1;
      -khtml-opacity: 1;
      -webkit-opacity: 1;
      opacity: 1;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=${opperc})";
      filter: "alpha(opacity=${opperc})";
      background: transparent; }
      .svg .sn a svg {
        width: 100%;
        height: auto;
        max-height: 25px;
        max-width: 25px;
        display: block; }
        .svg .sn a svg .st0 {
          -webkit-transition: all 0.2s ease-out;
          -moz-transition: all 0.2s ease-out;
          -o-transition: all 0.2s ease-out;
          transition: all 0.2s ease-out;
          fill: #115626; }
      .svg .sn a:hover svg .st0 {
        fill: #1a1a1a; }
  .svg .sn.on-color a svg .st0 {
    fill: #fff; }

.sn a.twitter {
  background-position: 6px 10px; }

.sn a.fb {
  background-position: 6px -83px; }

.sn a.google {
  background-position: 6px -173px; }

.sn a.instagram {
  background-position: 6px -263px; }

.sn a:hover {
  -moz-opacity: 1;
  -khtml-opacity: 1;
  -webkit-opacity: 1;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=${opperc})";
  filter: "alpha(opacity=${opperc})"; }

.instagram.loaded {
  padding-bottom: 0; }

.instagram.loaded #insta_wrapper {
  -moz-opacity: 1;
  -khtml-opacity: 1;
  -webkit-opacity: 1;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=${opperc})";
  filter: "alpha(opacity=${opperc})"; }

.instagram {
  margin-top: 0;
  padding-bottom: 50%;
  background-color: #1a1a1a; }
  .instagram .sn {
    margin-top: 6px; }
  .instagram #insta_wrapper {
    -moz-opacity: 0;
    -khtml-opacity: 0;
    -webkit-opacity: 0;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=${opperc})";
    filter: "alpha(opacity=${opperc})";
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -moz-box-wrap: nowrap;
    -webkit-box-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    -ms-flexbox-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    flex-flow: row wrap; }
    .instagram #insta_wrapper img {
      width: 100%;
      display: block;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;
      margin: 0;
      padding: 0;
      line-height: 0; }
    .instagram #insta_wrapper video {
      display: block;
      width: 100%;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;
      margin: 0;
      padding: 0;
      line-height: 0;
      overflow: hidden; }
    .instagram #insta_wrapper div.ig:after {
      content: "";
      float: left;
      display: block;
      padding-top: 100%; }
    .instagram #insta_wrapper div.ig {
      flex: 1 0 auto;
      position: relative;
      background-color: #1a1a1a;
      overflow: hidden;
      flex-basis: 25%; }
      @media handheld, only screen and (max-width: 768px) {
        .instagram #insta_wrapper div.ig {
          flex-basis: 50%; } }
      .instagram #insta_wrapper div.ig .likes {
        position: relative;
        z-index: 100;
        -moz-opacity: 0;
        -khtml-opacity: 0;
        -webkit-opacity: 0;
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=${opperc})";
        filter: "alpha(opacity=${opperc})"; }
        .instagram #insta_wrapper div.ig .likes b {
          display: block;
          position: absolute;
          top: 7px;
          left: 7px;
          color: rgba(255, 255, 255, 0.7);
          font-size: 13px;
          text-transform: uppercase;
          letter-spacing: .1em;
          font-weight: 400; }
      .instagram #insta_wrapper div.ig a:hover .likes {
        -moz-opacity: 1;
        -khtml-opacity: 1;
        -webkit-opacity: 1;
        opacity: 1;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=${opperc})";
        filter: "alpha(opacity=${opperc})"; }
      .instagram #insta_wrapper div.ig .inner {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center; }
      .instagram #insta_wrapper div.ig img,
      .instagram #insta_wrapper div.ig video {
        -moz-opacity: 0.8;
        -khtml-opacity: 0.8;
        -webkit-opacity: 0.8;
        opacity: 0.8;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=${opperc})";
        filter: "alpha(opacity=${opperc})";
        -webkit-transition: all 0.2s ease-out;
        -moz-transition: all 0.2s ease-out;
        -o-transition: all 0.2s ease-out;
        transition: all 0.2s ease-out; }
        .instagram #insta_wrapper div.ig img:hover,
        .instagram #insta_wrapper div.ig video:hover {
          -moz-opacity: 1;
          -khtml-opacity: 1;
          -webkit-opacity: 1;
          opacity: 1;
          -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=${opperc})";
          filter: "alpha(opacity=${opperc})"; }
      .instagram #insta_wrapper div.ig a:hover img,
      .instagram #insta_wrapper div.ig a:hover video {
        -moz-opacity: 1;
        -khtml-opacity: 1;
        -webkit-opacity: 1;
        opacity: 1;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=${opperc})";
        filter: "alpha(opacity=${opperc})"; }
      @media handheld, only screen and (max-width: 768px) {
        .instagram #insta_wrapper div.ig {
          width: 100%; }
          .instagram #insta_wrapper div.ig#ig-10, .instagram #insta_wrapper div.ig#ig-5, .instagram #insta_wrapper div.ig#ig-6, .instagram #insta_wrapper div.ig#ig-7, .instagram #insta_wrapper div.ig#ig-8, .instagram #insta_wrapper div.ig#ig-9 {
            display: none; } }

.show-us-tag {
  position: relative;
  width: 100%;
  z-index: 100;
  text-align: center;
  height: 1px;
  margin: 0 auto;
  top: -20px;
  height: 0; }
  .show-us-tag,
  .show-us-tag h3 {
    width: 280px; }
  .show-us-tag h1,
  .show-us-tag h2,
  .show-us-tag h3,
  .show-us-tag h4 {
    color: #fff; }
  .show-us-tag hr.mini {
    margin: 15px auto;
    border-color: #fff; }
  .show-us-tag a:active, .show-us-tag a.nice-select.open,
  .show-us-tag a:hover,
  .show-us-tag a:link,
  .show-us-tag a:visited {
    color: #fff; }
  .show-us-tag a:hover {
    color: rgba(255, 255, 255, 0.6); }
  .show-us-tag h3 {
    background: #1a1a1a;
    background: rgba(27, 42, 69, 0.6);
    position: absolute;
    font-size: 30px;
    line-height: 100%;
    text-transform: none;
    padding: 15px; }
    .show-us-tag h3 span {
      display: block;
      margin: 0 0 5px;
      font-size: 13px;
      line-height: 100%;
      font-weight: 200;
      font-family: "proxima-nova", sans-serif;
      text-transform: uppercase;
      letter-spacing: 0.15em;
      margin-right: -5px; }

.google-map {
  width: 100%;
  height: 400px;
  border: #ccc solid 1px;
  margin: 20px 0; }
  .google-map img {
    max-width: inherit !important; }

/*! jQuery UI - v1.12.1 - 2017-11-30
* http://jqueryui.com
* Includes: core.css, slider.css, theme.css
* To view and modify this theme, visit http://jqueryui.com/themeroller/?scope=&folderName=ui-lightness&cornerRadiusShadow=5px&offsetLeftShadow=-5px&offsetTopShadow=-5px&thicknessShadow=5px&opacityShadow=20&bgImgOpacityShadow=10&bgTextureShadow=flat&bgColorShadow=000000&opacityOverlay=50&bgImgOpacityOverlay=20&bgTextureOverlay=diagonals_thick&bgColorOverlay=666666&iconColorError=ffd27a&fcError=ffffff&borderColorError=cd0a0a&bgImgOpacityError=18&bgTextureError=diagonals_thick&bgColorError=b81900&iconColorHighlight=228ef1&fcHighlight=363636&borderColorHighlight=fed22f&bgImgOpacityHighlight=75&bgTextureHighlight=highlight_soft&bgColorHighlight=ffe45c&iconColorActive=ef8c08&fcActive=eb8f00&borderColorActive=fbd850&bgImgOpacityActive=65&bgTextureActive=glass&bgColorActive=ffffff&iconColorHover=ef8c08&fcHover=c77405&borderColorHover=fbcb09&bgImgOpacityHover=100&bgTextureHover=glass&bgColorHover=fdf5ce&iconColorDefault=ef8c08&fcDefault=1c94c4&borderColorDefault=cccccc&bgImgOpacityDefault=100&bgTextureDefault=glass&bgColorDefault=f6f6f6&iconColorContent=222222&fcContent=333333&borderColorContent=dddddd&bgImgOpacityContent=100&bgTextureContent=highlight_soft&bgColorContent=eeeeee&iconColorHeader=ffffff&fcHeader=ffffff&borderColorHeader=e78f08&bgImgOpacityHeader=35&bgTextureHeader=gloss_wave&bgColorHeader=f6a828&cornerRadius=4px&fsDefault=1.1em&fwDefault=bold&ffDefault=Trebuchet%20MS%2CTahoma%2CVerdana%2CArial%2Csans-serif
* Copyright jQuery Foundation and other contributors; Licensed MIT */
/* Layout helpers
----------------------------------*/
.ui-helper-hidden {
  display: none; }

.ui-helper-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.ui-helper-reset {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  line-height: 1.3;
  text-decoration: none;
  font-size: 100%;
  list-style: none; }

.ui-helper-clearfix:before,
.ui-helper-clearfix:after {
  content: "";
  display: table;
  border-collapse: collapse; }

.ui-helper-clearfix:after {
  clear: both; }

.ui-helper-zfix {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  opacity: 0;
  filter: Alpha(Opacity=0);
  /* support: IE8 */ }

.ui-front {
  z-index: 100; }

/* Interaction Cues
----------------------------------*/
.ui-state-disabled {
  cursor: default !important;
  pointer-events: none; }

/* Icons
----------------------------------*/
.ui-icon {
  display: inline-block;
  vertical-align: middle;
  margin-top: -.25em;
  position: relative;
  text-indent: -99999px;
  overflow: hidden;
  background-repeat: no-repeat; }

.ui-widget-icon-block {
  left: 50%;
  margin-left: -8px;
  display: block; }

/* Misc visuals
----------------------------------*/
/* Overlays */
.ui-widget-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.ui-slider {
  position: relative;
  text-align: left; }

.ui-slider .ui-slider-handle {
  position: absolute;
  z-index: 2;
  width: 1.2em;
  height: 2em;
  cursor: default;
  -ms-touch-action: none;
  touch-action: none;
  cursor: pointer; }

.ui-slider .ui-slider-range {
  position: absolute;
  z-index: 1;
  font-size: .7em;
  display: block;
  border: 0;
  background-position: 0 0; }

/* support: IE8 - See #6727 */
.ui-slider.ui-state-disabled .ui-slider-handle,
.ui-slider.ui-state-disabled .ui-slider-range {
  filter: inherit; }

.ui-slider-horizontal {
  height: 1.5em; }

.ui-slider-horizontal .ui-slider-handle {
  top: -.3em;
  margin-left: -.6em; }

.ui-slider-horizontal .ui-slider-range {
  top: 0;
  height: 100%; }

.ui-slider-horizontal .ui-slider-range-min {
  left: 0; }

.ui-slider-horizontal .ui-slider-range-max {
  right: 0; }

.ui-slider-vertical {
  width: 1.5em;
  height: 100px; }

.ui-slider-vertical .ui-slider-handle {
  left: -.3em;
  margin-left: 0;
  margin-bottom: -.6em; }

.ui-slider-vertical .ui-slider-range {
  left: 0;
  width: 100%; }

.ui-slider-vertical .ui-slider-range-min {
  bottom: 0; }

.ui-slider-vertical .ui-slider-range-max {
  top: 0; }

/* Component containers
----------------------------------*/
.ui-widget {
  font-family: Trebuchet MS,Tahoma,Verdana,Arial,sans-serif;
  font-size: 1.1em; }

.ui-widget .ui-widget {
  font-size: 1em; }

.ui-widget input,
.ui-widget select,
.ui-widget textarea,
.ui-widget button {
  font-family: Trebuchet MS,Tahoma,Verdana,Arial,sans-serif;
  font-size: 1em; }

.ui-widget.ui-widget-content {
  border: 1px solid #cccccc; }

.ui-widget-content {
  border: 1px solid #D7DDD9;
  color: #333333;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.ui-widget-content a {
  color: #333333; }

.ui-widget-header {
  border: 1px solid #e78f08;
  background: #f6a828 url("images/ui-bg_gloss-wave_35_f6a828_500x100.png") 50% 50% repeat-x;
  color: #ffffff;
  font-weight: bold; }

.ui-widget-header a {
  color: #ffffff; }

/* Interaction states
----------------------------------*/
.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default,
.ui-button,
html .ui-button.ui-state-disabled:hover,
html .ui-button.ui-state-disabled:active,
html .ui-button.ui-state-disabled.nice-select.open {
  border: none;
  background: #115626;
  font-weight: bold;
  color: #fff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); }

.ui-state-default a,
.ui-state-default a:link,
.ui-state-default a:visited,
a.ui-button,
a:link.ui-button,
a:visited.ui-button,
.ui-button {
  color: #1c94c4;
  text-decoration: none; }

.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus,
.ui-button:hover,
.ui-button:focus {
  background: #0e451e;
  font-weight: bold; }

.ui-state-hover a,
.ui-state-hover a:hover,
.ui-state-hover a:link,
.ui-state-hover a:visited,
.ui-state-focus a,
.ui-state-focus a:hover,
.ui-state-focus a:link,
.ui-state-focus a:visited,
a.ui-button:hover,
a.ui-button:focus {
  color: #c77405;
  text-decoration: none; }

.ui-visual-focus {
  box-shadow: 0 0 3px 1px #5e9ed6; }

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active,
a.ui-button:active,
a.ui-button.nice-select.open,
.ui-button:active,
.ui-button.nice-select.open,
.ui-button.ui-state-active:hover {
  background: #0e451e;
  transform: scale(1.1, 1.1); }

.ui-icon-background,
.ui-state-active .ui-icon-background {
  border: #fbd850;
  background-color: #eb8f00; }

.ui-state-active a,
.ui-state-active a:link,
.ui-state-active a:visited {
  color: #eb8f00;
  text-decoration: none; }

.nice-select {
  -webkit-tap-highlight-color: transparent;
  background-color: #fff;
  border-radius: 0.25rem;
  border: solid 1px #D7DDD9;
  box-sizing: border-box;
  clear: both;
  cursor: pointer;
  display: block;
  float: left;
  font-family: inherit;
  font-size: 17px;
  font-weight: normal;
  outline: none;
  padding: 10px 15px !important;
  margin: 0 0 30px 0 !important;
  padding-right: 30px !important;
  position: relative;
  text-align: left !important;
  transition: all 0.2s ease-in-out;
  user-select: none;
  white-space: nowrap;
  width: auto;
  min-height: 47px; }
  .nice-select:hover {
    border-color: #c9d1cc; }
  .nice-select:after {
    border-bottom: 2px solid #115626;
    border-right: 2px solid #115626;
    content: '';
    display: block;
    height: 5px;
    margin-top: -4px;
    pointer-events: none;
    position: absolute;
    right: 12px;
    top: 50%;
    transform-origin: 66% 66%;
    transform: rotate(45deg);
    transition: all 0.15s ease-in-out;
    width: 5px; }
  .nice-select.open:after {
    transform: rotate(-135deg); }
  .nice-select.open .list {
    opacity: 1;
    pointer-events: auto;
    transform: scale(1) translateY(0); }
  .nice-select.disabled {
    border-color: #dde2de;
    color: #999;
    pointer-events: none; }
    .nice-select.disabled:after {
      border-color: #22ab4c; }
  .nice-select.wide {
    width: 100%; }
    .nice-select.wide .list {
      left: 0 !important;
      right: 0 !important; }
  .nice-select.right {
    float: right; }
    .nice-select.right .list {
      left: auto;
      right: 0; }
  .nice-select.small {
    font-size: 14px;
    height: 36px;
    line-height: 34px; }
    .nice-select.small:after {
      height: 4px;
      width: 4px; }
    .nice-select.small .option {
      line-height: 34px;
      min-height: 34px; }
  .nice-select .list {
    background-color: #fff;
    border-radius: 0.25rem;
    box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
    box-sizing: border-box;
    margin: 0;
    margin-top: 4px;
    opacity: 0;
    overflow: hidden;
    padding: 0;
    pointer-events: none;
    position: absolute;
    top: 100%;
    left: 0;
    transform-origin: 50% 0;
    transform: scale(0.75) translateY(-calc(2.25rem + 2px)/2);
    transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
    z-index: 9; }
    .nice-select .list:hover .option:not(:hover) {
      background-color: transparent !important; }
  .nice-select .option {
    cursor: pointer;
    font-weight: 400;
    margin: 0 !important;
    padding: 10px 15px !important;
    line-height: 110%;
    list-style: none;
    min-height: calc(2.25rem + 2px)-2;
    outline: none;
    padding-left: 8px;
    padding-right: 19px;
    text-align: left;
    transition: all 0.2s;
    font-size: 17px; }
    .nice-select .option:hover {
      background-color: #115626;
      color: #fff !important; }
    .nice-select .option.selected {
      color: #115626; }
    .nice-select .option.disabled {
      background-color: transparent;
      color: #999;
      cursor: default; }

.no-csspointerevents .nice-select .list {
  display: none; }

.no-csspointerevents .nice-select.open .list {
  display: block; }

.icon {
  margin: 0 auto;
  margin-bottom: 15px; }

.molecule {
  cursor: pointer;
  width: 75px;
  margin: 0 auto; }
  .molecule .st0 {
    fill: none;
    stroke: #698381;
    stroke-width: 1; }
  .molecule .st1 {
    fill: #115626; }
  .molecule .st2 {
    fill: #fff; }
  .molecule #nucleus {
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out; }
  .molecule #orbits {
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    -moz-opacity: 0.8;
    -khtml-opacity: 0.8;
    -webkit-opacity: 0.8;
    opacity: 0.8;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=${opperc})";
    filter: "alpha(opacity=${opperc})"; }
    .molecule #orbits ellipse {
      -moz-opacity: 0.7;
      -khtml-opacity: 0.7;
      -webkit-opacity: 0.7;
      opacity: 0.7;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=${opperc})";
      filter: "alpha(opacity=${opperc})";
      animation: orbit-fade 8s linear infinite; }
    .molecule #orbits ellipse:first-child {
      animation: orbit-fade 4.5s linear infinite;
      animation-delay: 1s; }
    .molecule #orbits ellipse:last-child {
      animation: orbit-fade 3s linear infinite; }
  .molecule #electrons .st0 {
    fill: none;
    stroke: #115626;
    stroke-width: 3;
    stroke-miterlimit: 3;
    stroke-dasharray: .4, 90;
    stroke-linecap: round;
    stroke-dashoffset: 15000; }
  .molecule #electrons ellipse:first-child {
    stroke-dashoffset: 20000; }
  .molecule #electrons ellipse:last-child {
    stroke-dashoffset: 3700;
    stroke-dasharray: .4, 200; }
  .molecule:hover #orbits {
    -moz-opacity: 1;
    -khtml-opacity: 1;
    -webkit-opacity: 1;
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=${opperc})";
    filter: "alpha(opacity=${opperc})"; }
  .molecule:hover #electrons .st0 {
    stroke: #FBAE42; }
  .molecule:hover #nucleus {
    fill: #FBAE42; }
  .molecule #electrons .st0 {
    animation: dash 200s linear infinite; }
  .molecule #electrons ellipse:first-child {
    animation: dash 200s linear infinite; }
  .molecule #electrons ellipse:last-child {
    animation: dash 100s linear infinite; }

@keyframes dash {
  to {
    stroke-dashoffset: 0; } }

@-webkit-keyframes orbit-fade {
  0% {
    -moz-opacity: 0.3;
    -khtml-opacity: 0.3;
    -webkit-opacity: 0.3;
    opacity: 0.3;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=${opperc})";
    filter: "alpha(opacity=${opperc})"; }
  25% {
    -moz-opacity: 0.75;
    -khtml-opacity: 0.75;
    -webkit-opacity: 0.75;
    opacity: 0.75;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=${opperc})";
    filter: "alpha(opacity=${opperc})"; }
  50% {
    -moz-opacity: 0.95;
    -khtml-opacity: 0.95;
    -webkit-opacity: 0.95;
    opacity: 0.95;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=${opperc})";
    filter: "alpha(opacity=${opperc})"; }
  75% {
    -moz-opacity: 0.85;
    -khtml-opacity: 0.85;
    -webkit-opacity: 0.85;
    opacity: 0.85;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=${opperc})";
    filter: "alpha(opacity=${opperc})"; }
  100% {
    -moz-opacity: 0.35;
    -khtml-opacity: 0.35;
    -webkit-opacity: 0.35;
    opacity: 0.35;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=${opperc})";
    filter: "alpha(opacity=${opperc})"; } }

section.services {
  background: #1a1a1a; }
  section.services::before, section.services::after {
    flex-basis: 0;
    order: 1; }
  section.services .row {
    padding: 0;
    margin: 0; }
    section.services .row .grid-item {
      padding: 0 !important;
      margin: 0 !important;
      line-height: 100%;
      font-size: 0;
      background-size: cover;
      background-position: center center; }
      section.services .row .grid-item h3 {
        margin: 0;
        padding: 0;
        line-height: 100%; }
      section.services .row .grid-item a {
        background-color: rgba(28, 33, 35, 0.6);
        display: inline-block;
        min-height: 300px;
        box-sizing: border-box;
        padding: 30px;
        margin: 0;
        color: #fff;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -moz-box-wrap: nowrap;
        -webkit-box-wrap: nowrap;
        -webkit-flex-wrap: nowrap;
        -ms-flexbox-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -webkit-justify-content: center;
        -ms-flex-pack: justify;
        -moz-box-pack: justify;
        justify-content: center;
        -webkit-transition: background-color .2s ease-out;
        -moz-transition: background-color .2s ease-out;
        -o-transition: background-color .2s ease-out;
        transition: background-color .2s ease-out; }
        @media (max-width: 575px) {
          section.services .row .grid-item a {
            min-height: 200px; } }
        section.services .row .grid-item a:hover {
          background-color: rgba(0, 0, 0, 0.5); }

section.grid img {
  display: block;
  margin: 0;
  padding: 0;
  max-width: 100%;
  height: auto; }

section.grid .row.skinny-row {
  padding: 0;
  margin: 0 -25px; }

div.flexslider {
  margin-bottom: -10px;
  display: block; }
  div.flexslider .flex-control-paging li a {
    background: #1a1a1a;
    -moz-opacity: 0.3;
    -khtml-opacity: 0.3;
    -webkit-opacity: 0.3;
    opacity: 0.3;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=${opperc})";
    filter: "alpha(opacity=${opperc})"; }
  div.flexslider .flex-control-paging li a:hover {
    -moz-opacity: 1;
    -khtml-opacity: 1;
    -webkit-opacity: 1;
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=${opperc})";
    filter: "alpha(opacity=${opperc})";
    background: #115626;
    border-color: #115626; }
  div.flexslider .flex-control-paging li a.flex-active {
    -moz-opacity: 1;
    -khtml-opacity: 1;
    -webkit-opacity: 1;
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=${opperc})";
    filter: "alpha(opacity=${opperc})";
    background: #115626;
    border-color: #115626; }
  div.flexslider .flex-direction-nav a:before {
    background: url("../../assets/images/arrow-left.png") top center no-repeat;
    background-size: 35px 68px;
    -moz-opacity: 0.3;
    -khtml-opacity: 0.3;
    -webkit-opacity: 0.3;
    opacity: 0.3;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=${opperc})";
    filter: "alpha(opacity=${opperc})";
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out; }
    @media handheld, only screen and (max-width: 768px) {
      div.flexslider .flex-direction-nav a:before {
        display: none; } }
  div.flexslider .flex-direction-nav a:hover:before {
    -moz-opacity: 1;
    -khtml-opacity: 1;
    -webkit-opacity: 1;
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=${opperc})";
    filter: "alpha(opacity=${opperc})"; }
  div.flexslider .flex-direction-nav a.flex-next:before {
    background: url("../../assets/images/arrow-left.png") top center no-repeat;
    background-size: 35px 68px;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg); }
  div.flexslider ul.slides li {
    background-size: cover;
    background-position: center center;
    padding: 50px 0; }
    div.flexslider ul.slides li .content {
      width: 65%;
      margin: 0 auto; }
      @media handheld, only screen and (max-width: 768px) {
        div.flexslider ul.slides li .content {
          width: auto;
          margin: 0 25px; } }

.fcslider {
  background: #F2F9F2;
  padding: 40px 0; }
  .fcslider .flexslider, .fcslider .flexslider li {
    padding-top: 0 !important; }
  .fcslider .flexslider .flex-direction-nav a:before {
    background: url("../../assets/images/arrow-left-dark.png") top center no-repeat;
    background-size: 35px 68px;
    -moz-opacity: 0.3;
    -khtml-opacity: 0.3;
    -webkit-opacity: 0.3;
    opacity: 0.3;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=${opperc})";
    filter: "alpha(opacity=${opperc})";
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out; }
    @media handheld, only screen and (max-width: 768px) {
      .fcslider .flexslider .flex-direction-nav a:before {
        display: none; } }
  .fcslider .flexslider .flex-direction-nav a.flex-next:before {
    background: url("../../assets/images/arrow-left-dark.png") top center no-repeat;
    background-size: 35px 68px;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg); }

@-webkit-keyframes Gradient {
  0% {
    background-position: 0% 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0% 50%; } }

@-moz-keyframes Gradient {
  0% {
    background-position: 0% 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0% 50%; } }

@keyframes Gradient {
  0% {
    background-position: 0% 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0% 50%; } }

.page-title {
  background: linear-gradient(-45deg, black, #4d4d4d, #1a1a1a, #666666);
  background-size: 400% 400%;
  -webkit-animation: Gradient 15s ease infinite;
  -moz-animation: Gradient 15s ease infinite;
  animation: Gradient 15s ease infinite;
  clear: both; }
  .page-title .overlay {
    height: 600px;
    box-sizing: border-box;
    padding: 30px 30px;
    text-align: center;
    background: rgba(0, 0, 0, 0.5);
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -moz-box-wrap: nowrap;
    -webkit-box-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    -ms-flexbox-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    @media (max-width: 767px) {
      .page-title .overlay {
        height: 400px;
        padding-top: 80px; } }
    .page-title .overlay.no-content {
      background: transparent; }
    .page-title .overlay .flex-container {
      margin: 0 auto;
      flex-grow: 1; }
    .page-title .overlay h1,
    .page-title .overlay h2,
    .page-title .overlay h3,
    .page-title .overlay h4,
    .page-title .overlay p {
      color: #fff; }
    .page-title .overlay h1 {
      margin: 0 auto;
      padding: 0;
      font-weight: 200;
      text-transform: none;
      letter-spacing: 0;
      font-size: 35px;
      font-family: "proxima-nova", sans-serif;
      line-height: 130%;
      max-width: 70%; }
      @media (max-width: 767px) {
        .page-title .overlay h1 {
          font-size: 29px; } }
      @media (max-width: 575px) {
        .page-title .overlay h1 {
          max-width: 100%; } }
    .page-title .overlay p {
      margin: 15px 25px 0; }
  .page-title.content-bottom .overlay {
    -webkit-box-align: flex-end;
    -webkit-align-items: flex-end;
    -moz-box-align: flex-end;
    -ms-flex-align: flex-end;
    align-items: flex-end; }
  .page-title.content-top .overlay {
    -webkit-box-align: flex-start;
    -webkit-align-items: flex-start;
    -moz-box-align: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start; }

html .page-title.inplace {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-animation: none;
  -moz-animation: none;
  animation: none; }
  html .page-title.inplace.img-bottom {
    background-position: center bottom; }
  html .page-title.inplace.img-top {
    background-position: center top; }

.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }

section.branded {
  background: #EFF4F4;
  padding: 50px 0; }

section.has-bg-img {
  background: #1a1a1a;
  background-size: 60px 60px;
  clear: both; }
  section.has-bg-img .overlay {
    padding: 80px 0;
    background: rgba(6, 42, 61, 0.25); }
  section.has-bg-img, section.has-bg-img p, section.has-bg-img h1, section.has-bg-img h2, section.has-bg-img h3, section.has-bg-img h4, section.has-bg-img h5, section.has-bg-img h6 {
    color: #fff; }

html section.has-bg-img.loaded {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; }

section.margin-top-sm {
  margin-top: 20px; }

section.margin-top-md {
  margin-top: 50px; }

section.margin-top-lg {
  margin-top: 100px; }

section.margin-top-none {
  margin-top: 0; }

section.margin-bot-sm {
  margin-bottom: 20px; }

section.margin-bot-md {
  margin-bottom: 50px; }

section.margin-bot-lg {
  margin-bottom: 100px; }

section.margin-bot-none {
  margin-bottom: 0; }

section img.aligncenter {
  display: block;
  margin: 0 auto; }

section img.alignright {
  display: block;
  margin: 0 0 0 auto; }

.testimonials.full-list .testimonial {
  margin-bottom: 50px;
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  padding: 20px;
  background-color: #fff; }
  @media (max-width: 767px) {
    .testimonials.full-list .testimonial {
      padding: 10px; } }

.testimonial-slider div.flexslider {
  background: rgba(0, 0, 0, 0.02);
  background: #115626;
  margin-bottom: -10px;
  display: block; }
  .testimonial-slider div.flexslider ul.slides li {
    padding: 30px 0 50px; }
    .testimonial-slider div.flexslider ul.slides li .overlay {
      text-align: center;
      min-height: 300px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -moz-box-wrap: nowrap;
      -webkit-box-wrap: nowrap;
      -webkit-flex-wrap: nowrap;
      -ms-flexbox-wrap: nowrap;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      align-items: center; }
    .testimonial-slider div.flexslider ul.slides li .content {
      width: 65%;
      margin: 0 auto; }
      .testimonial-slider div.flexslider ul.slides li .content, .testimonial-slider div.flexslider ul.slides li .content p, .testimonial-slider div.flexslider ul.slides li .content h1, .testimonial-slider div.flexslider ul.slides li .content h2, .testimonial-slider div.flexslider ul.slides li .content h3, .testimonial-slider div.flexslider ul.slides li .content h4 {
        color: #fff; }
      .testimonial-slider div.flexslider ul.slides li .content, .testimonial-slider div.flexslider ul.slides li .content p {
        font-size: 22px; }
      @media handheld, only screen and (max-width: 768px) {
        .testimonial-slider div.flexslider ul.slides li .content {
          width: auto;
          margin: 0 25px; } }
