/*
 * jQuery FlexSlider v2.6.0
 * http://www.woothemes.com/flexslider/
 *
 * Copyright 2012 WooThemes
 * Free to use under the GPLv2 and later license.
 * http://www.gnu.org/licenses/gpl-2.0.html
 *
 * Contributing author: Tyler Smith ($mbmufffin)
 *
 */
/* ====================================================================================================================
 * FONT-FACE
 * ====================================================================================================================*/
/*
$font-face {
  font-family: 'flexslider-icon';
  src: url('fonts/flexslider-icon.eot');
  src: url('fonts/flexslider-icon.eot?#iefix') format('embedded-opentype'), url('fonts/flexslider-icon.woff') format('woff'), url('fonts/flexslider-icon.ttf') format('truetype'), url('fonts/flexslider-icon.svg#flexslider-icon') format('svg');
  font-weight: normal;
  font-style: normal;
}
*/
/* ====================================================================================================================
 * RESETS
 * ====================================================================================================================*/
.flex-container a:hover,
.flex-slider a:hover {
  outline: none;
}

.flex-control-nav,
.flex-direction-nav,
.slides,
.slides > li {
  margin: 0;
  padding: 0;
  list-style: none;
}

.flex-pauseplay span {
  text-transform: capitalize;
}
/* ====================================================================================================================
 * BASE STYLES
 * ====================================================================================================================*/
.flexslider {
  margin: 0;
  padding: 0;
}

.flexslider .slides > li {
  display: none;
  -webkit-backface-visibility: hidden;
}

.flexslider .slides img {
  width: 100%;
  display: block;
}

.flexslider .slides:after {
  content: "\0020";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

html[xmlns] .flexslider .slides {
  display: block;
}

* html .flexslider .slides {
  height: 1%;
}

.no-js .flexslider .slides > li:first-child {
  display: block;
}
/* ====================================================================================================================
 * DEFAULT THEME
 * ====================================================================================================================*/
.flexslider {
  margin: 0;
  position: relative;
  zoom: 1;
}

.flexslider .slides {
  zoom: 1;
}

.flexslider .slides img {
  height: auto;
  -moz-user-select: none;
}

.flex-viewport {
  max-height: 2000px;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.loading .flex-viewport {
  max-height: 300px;
}

.carousel li {
  margin-right: 5px;
}

.flex-direction-nav {
  height: 0;
}

.flex-direction-nav a {
  text-decoration: none;
  display: block;
  width: 40px;
  height: 68px;
  margin: -34px 0 0;
  position: absolute;
  top: 50%;
  z-index: 10;
  overflow: hidden;
  cursor: pointer;

  @media handheld, only screen and (max-width: 768px) {
    display: none;
  }
}

.flex-direction-nav a:before {
  font-family: "flexslider-icon";
  font-size: 40px;
  display: inline-block;
  content: '';
  color: rgba(0, 0, 0, 0.8);
  //text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.3);
  background: url("#{$images}/arrow-left.png") top center no-repeat;
  width: 35px;
  height: 68px;
  background-size: 35px 68px;

  @media handheld, only screen and (max-width: 768px) {
    display: none;
  }
}

.flex-direction-nav a.flex-next:before {
  content: '';
  background: url("#{$images}/arrow-left.png") top center no-repeat;
  width: 35px;
  height: 68px;
  background-size: 35px 68px;
  @include rotation(180deg);
}

.flex-direction-nav .flex-prev {
  left: -50px;
}

.flex-direction-nav .flex-next {
  right: -50px;
  text-align: right;
}

.flexslider .flex-direction-nav .flex-prev {
  left: 10px;
}

.flexslider:hover .flex-direction-nav .flex-prev:hover {
  opacity: 1;
}

.flexslider .flex-direction-nav .flex-next {
  right: 10px;
}

.flexslider:hover .flex-direction-nav .flex-next:hover {
  opacity: 1;
}

.flex-direction-nav .flex-disabled {
  opacity: 0!important;
  filter: alpha(opacity=0);
  cursor: default;
  z-index: -1;
}

.flex-pauseplay a {
  display: block;
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 5px;
  left: 10px;
  opacity: 0.8;
  z-index: 10;
  overflow: hidden;
  cursor: pointer;
  color: #000;
}

.flex-pauseplay a:before {
  font-family: "flexslider-icon";
  font-size: 20px;
  display: inline-block;
  content: '\f004';
}

.flex-pauseplay a:hover {
  opacity: 1;
}

.flex-pauseplay a.flex-play:before {
  content: '\f003';
}

.flex-control-nav {
  width: 100%;
  position: absolute;
  bottom: 20px;
  text-align: center;
}

.flex-control-nav li {
  margin: 0 6px;
  display: inline-block;
  zoom: 1;
  display: inline;
}

.flex-control-paging {
  //position: relative;
  z-index: 1000;
  @media screen and (max-width: 769px) {
    //display: none;
  }
}

.flex-control-paging li a {
  width: 12px;
  height: 12px;
  display: inline-block;
  background: #fff;
  cursor: pointer;
  text-indent: -9999px;
  @include opacity(.5);
  @include rounded(6px);
}

.flex-control-paging li a:hover {
  @include opacity(1);
}

.flex-control-paging li a.flex-active {
  @include opacity(1);
  cursor: default;
}

.flex-control-thumbs {
  margin: 5px 0 0;
  position: static;
  overflow: hidden;
}

.flex-control-thumbs li {
  width: 25%;
  float: left;
  margin: 0;
}

.flex-control-thumbs img {
  width: 100%;
  height: auto;
  display: block;
  opacity: 0.7;
  cursor: pointer;
  -moz-user-select: none;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.flex-control-thumbs img:hover {
  opacity: 1;
}

.flex-control-thumbs .flex-active {
  opacity: 1;
  cursor: default;
}
/* ====================================================================================================================
 * RESPONSIVE
 * ====================================================================================================================*/
@media screen and (max-width: 860px) {
  .flex-direction-nav .flex-prev {
    opacity: 1;
    left: 10px;
  }

  .flex-direction-nav .flex-next {
    opacity: 1;
    right: 10px;
  }
}
