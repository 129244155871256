.page-heading {
  margin: 50px 0;

  .col {
    @include flex-it;
    @include flex-align-item-center;
    @include flex-justify-center;

    h1 {
      font-family: $font-menu;
      font-size: 24px;
      line-height: 100%;
      text-transform: uppercase;
      letter-spacing: 0.08em;
      margin: 0;

      html.wf-active & {

      }
    }
    span {
      height: 50px;
      width: 1px;
      border-left: 1px solid $color-accent;
      margin: 0 20px;
    }
    h2 {
      font-size: 34px;
      margin: 0;
    }

    @media handheld, only screen and (max-width: 767px) {
      display: block;
      span {
        width: 50px;
        height: 1px;
        border: 0;
        border-top: 1px solid $color-accent;
        margin: 20px 0;
        display: block
      }
    }
  }

}



body.single-lab_portfolio {
  .page-title {
    background-color: $color-base;
    .overlay {
      background: rgba(0, 0, 0, .25);
    }
  }
}

.page-title {
  background: rgba(6, 42, 61, .9) url("#{$images}/loading.gif") center center no-repeat;
  background-size: 60px 60px;
  clear: both;

  .overlay {
    height: 450px;
    text-align: center;
    background: rgba(6, 42, 61, .25);
    &.no-content {
      background: transparent;
    }
    @include flex-it;
    @include flex-align-item-center;

    .flex-container {
      margin: 0 auto;
    }

    @media handheld, only screen and (max-width: 768px) {

    }


    h1,
    h2,
    h3,
    h4,
    p {
      color: #fff;
    }


    h1 {
      margin: 0 25px;
      padding: 0;
      font-weight: 200;
    }

    p {
      margin: 15px 25px 0;
    }
  }
}

html {
  .page-title {
      &.inplace {
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;

      }
  }
}




.linear-action {
  .row {
    div {
      @include flex-it;
      @include flex-align-item-center;
      @include flex-justify-center;
      @include media-breakpoint-down(md) {
        display: block;
      }

      h3 {
        margin-right: 25px;
        @include media-breakpoint-down(md) {
          margin-right: 0;
        }
      }
    }
  }
}






section.featured {
  padding: 80px 0;
  clear: both;
  @include media-breakpoint-down(sm) {
    padding: 80px 0 0;
  }
  text-align: center;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+49,ffffff+53,ffffff+100 */
  background: #F2F9F2; /* Old browsers */
  background: -moz-linear-gradient(left, #F2F9F2 0%, #F2F9F2 50%, $color-base 50%, $color-base 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #F2F9F2 0%,#F2F9F2 50%,$color-base 50%,$color-base 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #F2F9F2 0%,#F2F9F2 50%,$color-base 50%,$color-base 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  // Flipped
  &.flipped {
    @include media-breakpoint-down(sm) {
      padding-bottom: 80px;
    }
    background: #F2F9F2; /* Old browsers */
    background: -moz-linear-gradient(left, $color-base 0%, $color-base 50%, #F2F9F2 50%, #F2F9F2 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, $color-base 0%,$color-base 50%,#F2F9F2 50%,#F2F9F2 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, $color-base 0%,$color-base 50%,#F2F9F2 50%,#F2F9F2 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  }
  &, &.flipped {
    @include media-breakpoint-down(sm) {
      background: transparent;
    }
  }

  a.butt {
    padding: 15px 30px;
    border: 10px solid lighten($color-base, 7%);
    @include media-breakpoint-down(sm) {
      border: none;
    }
  }

  .row {
    padding-left: 0;
    padding-right: 0;
  }

  .col-md-6 {
    padding-left: 50px;
    padding-right: 50px;

    p {
      margin: 0;
    }

    img {
      max-width: 130px;
      margin-bottom: 40px;
    }
  }
}



section.page-title-alt {
  text-align: center;
  background: -moz-linear-gradient(top, $color-base 0%, $color-base 50%, #fff 50%, #fff 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, $color-base 0%,$color-base 50%,#fff 50%,#fff 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, $color-base 0%,$color-base 50%,#fff 50%,#fff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

  padding-top: 60px;
  h1 {
    color: #fff;
    font-size: 26px;
    line-height: 130%;
    font-style: normal;

    html.wf-active & {
      font-size: 30px;
      line-height: 100%;
      text-transform: uppercase;
      letter-spacing: 0.02em;
    }
  }
}
